<script>
export default {
  name: 'GuildTooltip',
  props: {
    guild: {},
    playerId: null
  }
}
</script>

<template>
  <el-tooltip
    placement="right-end"
    :show-after="300"
  >
    <template #content>
      <div>Name: <b>{{ guild.Name }}</b></div>
      <div>Chat messages: <b>{{ $utils.numberFormat(guild.ChatMessages) }}</b></div>
      <div>Battle scores: <b>{{ guild.BattlesScoreSum }}M</b></div>
      <div>
        <table>
          <tr>
            <td
              v-for="(row, i) in guild.BattlesScore"
              :key="i"
              style="height: 12px; width: 12px;"
              :style="`background-color: ${row.color};`"
            />
          </tr>
        </table>
      </div>
      <div>Payers paid:</div>
      <div>
        <table>
          <tr>
            <td
              v-for="(row, i) in guild.PayersNow"
              :key="i"
              style="height: 12px; width: 12px;"
              :style="`background-color: ${row.colorPaid};${row.ID === playerId ? 'border: 1px solid red;' : ''}`"
            />
          </tr>
        </table>
      </div>
      <div>Payers scores:</div>
      <div>
        <table>
          <tr>
            <td
              v-for="(row, i) in guild.PayersNow"
              :key="i"
              style="height: 12px; width: 12px;"
              :style="`background-color: ${row.color};${row.ID === playerId ? 'border: 1px solid red;' : ''}`"
            />
          </tr>
        </table>
      </div>
      <div v-if="guild.PayersBefore.length">
        No payers scores:
      </div>
      <div v-if="guild.PayersBefore.length">
        <table>
          <tr>
            <td
              v-for="(row, i) in guild.PayersBefore"
              :key="i"
              style="height: 12px; width: 12px;"
              :style="`background-color: ${row.color};`"
            />
          </tr>
        </table>
      </div>
      <div>Battle online players:</div>
      <div>
        <table>
          <tr>
            <td
              v-for="(row, i) in guild.BattlesPlayers"
              :key="i"
              style="height: 12px; width: 12px;
                                border: 1px solid white; font-size: 8px;"
            >
              {{ row }}
            </td>
          </tr>
        </table>
      </div>
    </template>
    <div>
      <el-progress
        :text-inside="true"
        :stroke-width="4"
        :percentage="Math.min((guild.ChatMessages / 1000) * 100, 100)"
        :color="guild.ChatMessagesColor"
        class="w-20"
      >
        <span />
      </el-progress>
      <el-progress
        :text-inside="true"
        :stroke-width="6"
        :percentage="guild.BattlesScoreSumPercentage"
        :color="guild.BattlesScoreSumColor"
        class="w-20"
      >
        <span />
      </el-progress>
      <table>
        <tr>
          <td
            v-for="(row, i) in guild.BattlesScore"
            :key="i"
            style="height: 6px; width: 6px;"
            :style="`background-color: ${row.color};`"
          />
        </tr>
      </table>
    </div>
  </el-tooltip>
</template>

<style scoped>
.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}
</style>
