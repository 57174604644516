<script>
/***
 * *** slots legend ***
 *
 * *** top slots ***
 *
 * 1. topLeft
 * 2. topRight
 * 3. topExpanded (for fixed table position absolute, max height 400px; scrolled)
 *
 *  *** headers table slots ***
 *                        scopedProps - look at definitions slots;
 * 1a. header_default (text headers - default);
 * 1b. header_[colApiName] (text header);
 * 2a. header_sort_default (slot for sorting component - default)
 * 2b. header_sort_[colApiName] (slot for sorting component)
 * 3a. header_search_default (slot for searching field in header - default)
 * 3b. header_search_[colApiName] (slot for searching field in header)
 *
 *  *** cell table slots ***
 *                        scopedProps - look at definitions slots;
 * 1. cell_default (text cell - default);
 * 2. cell_[colApiName] (text cell);
 * 3. cell_action (cell of action column)
 *
 *  *** cell edit dialog slots ***
 *                        scopedProps - look at definitions slots;
 *                        look also at props: enableCellEditDialogs / indexColumn / columnsSettings (cellEdit)
 *                        edit cell on click
 * 1. cell_edit_default (content of edit dialog for all cells)
 * 2. cell_edit_[colApiName] (content of edit dialog for all cells named col)
 * 3. cell_edit_actions_default (actions content of edit dialog for all cells)
 * 4. cell_edit_actions_[colApiName]  (actions content of edit dialog for all cells named col)
 *
 * *** expand_row slot ***
 *                        scopedProps - look at definitions slots;
 * 1. expand_row (for all rows)
 *
 * *** drawers slot ***
 *                       scopedProps - look at definitions slots;
 * 1. drawers (used for drawers that have to refresh data)
 *
 * *** bottom bar slots ***
 * 1. bottomBarLeft
 * 2. pagination
 *
 * *** another slots ***
 * 1. topTable (area above table)
 * 2. table (overwrites el-table and bottom bar)
 *
 * ***/
import { mapGetters } from 'vuex'
import { computed, defineAsyncComponent } from 'vue'
import {
  Bottom,
  Check,
  CircleClose,
  DCaret,
  Delete,
  Edit,
  EditPen,
  Finished,
  Operation,
  Refresh,
  Search,
  Top
} from '@element-plus/icons-vue'
import { debounce, merge } from 'lodash'
import { useCssVar } from '@vueuse/core'
import { Versioned } from './mixins/crud-table/Versioned'
import { CellEdit } from './mixins/crud-table/CellEdit'
import { Actions } from './mixins/crud-table/Actions'
import { Pagination } from './mixins/crud-table/Pagination'
import { Sorting } from './mixins/crud-table/Sorting'
import { FilteringData } from './mixins/crud-table/FilteringData'
import { Columns, RenderApiColumnType } from './mixins/crud-table/Columns'
import { FetchData } from './mixins/crud-table/FetchData'
import { SelectMode } from './mixins/crud-table/SelectMode'
import CellContent from '!/components/crud/sub/CellContent.vue'
import CrudTypeFields from '!/components/crud/sub/CrudTypeFields.vue'
import CrudSelectorsColumns from '!/components/crud/sub/CrudSelectorsColumns.vue'
import CrudSettingsBox from '!/components/crud/sub/CrudSettingsBox.vue'
import { userRightRoles } from '!/composition/utilities'
import SyncTags from '!/components/crud/sub/SyncTags.vue'

const builtInActionsOnListSettings = {
  crudPreview: {
    label: 'edit',
    withTooltip: true,
    link: true,
    right: userRightRoles.user,
    elAttr: { /* type: '', */ class: 'gs-btn-outlined-primary-light ml-0.5', icon: Edit },
    api: null
  },
  crudDelete: {
    label: 'delete',
    withTooltip: true,
    link: false,
    right: userRightRoles.editor,
    elAttr: { /* type: '', */ class: 'gs-btn-outlined-danger-light ml-0.5', icon: Delete },
    api: null
  }
}

export default {
  name: 'CrudTable',
  components: {
    Operation,
    CircleClose,
    EditPen,
    CrudTypeFields,
    Refresh,
    CellContent,
    CrudSelectorsColumns,
    CrudSettingsBox,
    SyncTags,
    ImportFromCsv: defineAsyncComponent(() => import('!/components/shared/ImportFromCsv.vue')),
    EnvFiltersTags: defineAsyncComponent(() => import('!/components/crud/sub/EnvFiltersTags.vue')),
    VersionsInfoTags: defineAsyncComponent(() => import('!/components/crud/sub/VersionsInfoTags.vue')),
    AbSelector: defineAsyncComponent(() => import('!/components/selectors/AbSelector.vue')),
    CrudVersionsTable: defineAsyncComponent(() => import('!/components/crud/sub/CrudVersionsTable.vue')),
    ToggleAllSyncTags: defineAsyncComponent(() => import('!/components/crud/sub/ToggleAllSyncTags.vue')),
    SyncErrorsDialog: defineAsyncComponent(() => import('!/components/crud/sub/SyncErrorsDialog.vue')),
    ApiValidationWarningsDialog: defineAsyncComponent(() => import('!/components/crud/sub/ApiValidationWarningsDialog.vue')),
    RowsMultiSelectColumn: defineAsyncComponent(() => import('!/components/crud/sub/RowsMultiSelectColumn.vue')),
    ExcelExportActionBtn: defineAsyncComponent(() => import('!/components/crud/sub/ExcelExportActionBtn.vue'))
  },
  mixins: [Versioned, CellEdit, Actions, Pagination, Sorting, FilteringData, Columns, RenderApiColumnType, FetchData, SelectMode],
  provide() {
    return {
      crudTable: this,
      searchFields: computed(() => this.searchFieldsRefer)
    }
  },
  props: {
    /** ** props config columns */
    columnsSettings: {
      type: [Object, Boolean],
      default: () => ({
        // 'colNameApi': {
        //   header: 'txt header', (optional - default colApiName)
        //   htmlValue: true, (use v-html on div (also requires a elAttr.formatter for security reasons))
        //   filterField: [false | true (name === colNameApi) | name_field (look at more info below)], (optional - default: true)
        //   elAttr: {attr for el-table-column (https://element-plus.org/en-US/component/table.html#table-column-attributes)}, (optional)
        //   width: shortcut for elAttr.width settings (optional)
        //   cellEdit: [false | true] (optional - default: true;  check also enableCellEditDialogs prop; edit cell on click !!!)
        // } | false - hide column
        //
        // more info:
        // name of filterField defines data filtering/searching method; eg name === colNameApi finds data equal to the searched value; name colNameApi_like finds data containing the searched value
        //
        // ID column have default settings (built-in settings)
      })
    },
    defaultColumnsSettings: {
      type: Object,
      default: () => ({ elAttr: { 'min-width': 250 } }) // like for single item of columnSettings prop
    },
    defaultVisibleColumns: {
      type: Array,
      default: () => [
        // 'nameApi column'
      ]
    },
    indexColumn: {
      // apiNameColumn containing a unique key of row (ID by default)
      type: String,
      default: 'ID'
    },
    /** ** props api */
    api: {
      // api used for list
      type: String,
      default: ''
    },
    apiItem: {
      // api used to edit single row (item) (like for edit page)
      type: String,
      default: ''
    },
    apiPrefix: {
      type: String,
      default: '/admin/api/'
    },
    apiPagination: {
      type: Boolean,
      default: true
    },
    apiFiltering: {
      type: Boolean,
      default: true
    },
    apiSorting: {
      type: Boolean,
      default: true
    },
    entity: {
      type: String,
      default: ''
    },
    multiEntity: {
      // when rows can have different entity (field Entity in row data is required; versioned children and children_map should be grouped by entity);
      type: Boolean,
      default: false
    },
    staticApiParams: {
      type: Object,
      default: () => ({
        // nameFilteredField: 'filteredValue' //filter params always added to request api
        // IMPORTANT: !!! to avoid conflicts it is recommended to set static filtered columns as hidden (set in columnsSettings to false)
        //
        // more info:
        // name of filterField defines data filtering/searching method; eg name === colNameApi finds data equal to the searched value; name colNameApi_like finds data containing the searched value
      })
    },
    /**
     * params used by proxy requests
     */
    proxyRequestParams: {
      type: Object,
      default: () =>
        // example:
        ({
          // uri: '/proxy/gameplay/divisionspreview/',
          // method: 'GET',
          // env: 'prod', //default stored in $store.getters['auth/envs'] (returned by api)_
          // dynamicUriParams: ['env']
          // envsUrls: {prod: 'url'} // to overwrite $store.getters['auth/envs']
        })
    },
    /** ** props config actions */
    actionColSettings: {
      type: [Object, Boolean],
      default: () => ({
        // {label: 'txt header', elAttr: {attr for el-table-column}} | false - hide action column
        label: 'Actions',
        elAttr: {
          width: 60
        }
      })
    },
    actionsOnList: {
      type: Object,
      default: () => ({
        // '[crud]NameAction' : {settings object}; actions whose name begins with  'crud' when clicked, they call the built-in method with the same name
        // it is merged with builtInActionsOnListSettings (definition above default export)
      })
    },
    /** ** props to overwrite/expand api (or used when is not set api prop - external data) */
    rows: {
      type: Array,
      default: undefined
    },
    rowsTotal: {
      type: Number,
      default: undefined
    },
    columns: {
      type: Object,
      default: () => ({
        // structure like in api
        // {[nameApi of column]: [name type of column returned by api eg 'string|bool|uint;  empty string means type string']{
      })
    },
    loading: {
      type: Boolean,
      default: false
    },
    /** ** crud options */
    defaultTableSettings: {
      type: Object,
      default: () => ({
        fixed: true,
        fit: true,
        visibleCellEdit: true
      })
    },
    rowsOnPage: {
      type: Number,
      default: 50
    },
    rowsOnPageList: {
      type: Array,
      default: () => [50, 100, 200, 500, 1000]
    },
    defaultSorting: {
      type: Object,
      default: () => ({
        column: '', // apiColName
        desc: false
      })
    },
    searchFields: {
      // refer to object used to store model values of search fields in top slots
      type: [Object],
      default: undefined
    },
    enableCellEditDialogs: {
      // allow edit cell of table (except: indexColumn prop or if column settings cellEditDialog === false); edit cell on click;
      type: Boolean,
      default: true
    },
    topSlotHeight: {
      type: Number,
      default: 0
    },
    versioned: {
      // show versioned components
      type: Boolean,
      default: true
    },
    showImgPreviewColumn: {
      type: Boolean,
      default: false
    },
    topActions: {
      // build-in top actions (if true all action); important: visibility also depends on the rights (look also on excelExportSettings.rights prop)
      type: [Object, Boolean],
      default: () => ({
        excelExport: true,
        importExcel: true,
        addNew: true
      })
    },
    excelExportSettings: {
      // settings for top action: export rows
      type: Object,
      default: () => ({
        maxRows: 50000, // maximum rows exported (for all chunks)
        chunks: 50000 // number of lines retrieved per request api ( export waits for all chunks),
        // right: userRightRoles.editor // default userRightRoles.editor
      })
    },
    importExcelSettings: {
      // settings for top action: import rows
      type: Object,
      default: () => ({
        // right: userRightRoles.editor // default userRightRoles.editor
      })
    },
    addNewSettings: {
      // settings for top action:  addItem
      type: Object,
      default: () => ({
        // right: userRightRoles.editor // default userRightRoles.editor
        // dropdownList: [ {label: '', route: null}] // when used as// dropdown btn
        // routeName: '' // to overwrite default edit route; it can be functions with passed row, that return route settings obj (used by $router.resolve);
      })
    },
    tableProps: {
      // to pass props used by el-table https://element-plus.org/en-US/component/table.html#table-attributes
      type: Object,
      default: undefined
    },
    accessRight: {
      type: String,
      default: userRightRoles.user
    },
    /**
     *  modification of loaded before connecting to the table
     *  must return data object;
     */
    renderLoadedData: {
      type: Function,
      default(loadData) {
        return loadData
      }
    },
    hideBottomActionBar: {
      type: Boolean,
      default: false
    },
    disablePagination: {
      type: Boolean,
      default: false
    },
    hidePaginationSize: {
      type: Boolean,
      default: false
    },
    hideColumnsSelector: {
      type: Boolean,
      default: false
    },
    hideSettingsSelector: {
      type: Boolean,
      default: false
    },
    hideMaxId: {
      type: Boolean,
      default: false
    },
    hideClearBtn: {
      type: Boolean,
      default: false
    },
    hideRefreshBtn: {
      type: Boolean,
      default: false
    },
    hideVersionedTopFilters: {
      type: Boolean,
      default: false
    },
    disableMultiSelectRows: {
      type: Boolean,
      default: false
    },
    disableMultiDuplicates: {
      type: Boolean,
      default: false
    },
    enabledItemTagsRelations: {
      type: Boolean,
      default: false
    },
    enableNumberRows: {
      type: Boolean,
      default: false
    },
    /** ** when page used as selector */
    /**
     *  enable select mode
     */
    selectMode: {
      type: Boolean,
      default: false
    },
    /**
     * current selected row (it should be changed by change-selection event )
     */
    selectedRow: {
      type: Object,
      default: undefined
    },
    selectedRowIndex: {
      type: String,
      default: 'ID'
    },
    route: {
      // force route used by this component,  used when this component (page) is imported in modal;
      type: Object,
      default: undefined
    },
    groupBy: {
      type: String,
      default: ''
    },
    /**
     * key used by api stored documentation;
     * default docKey === entity
     * It should be set the same as on the crud details pages
     */
    docKey: {
      type: String,
      default: undefined
    }
  },
  emits: ['fetchData', 'clearSearchData', 'change-meta-data', 'change-selection', 'resetAll'],
  setup(props) {
    const actionsOnListSettings = merge({}, builtInActionsOnListSettings, props.actionsOnList)
    if (!props.selectMode) {
      const actionsBarHeight = useCssVar('--gs-actions-bar-height', document.documentElement)
      actionsBarHeight.value = '0px'
    }
    return {
      actionsOnListSettings,
      icons: {
        Check,
        Bottom,
        Top,
        DCaret,
        Search,
        Finished
      }
    }
  },
  data() {
    return {
      showTopExpand: false
    }
  },
  computed: {
    usedTopSlots() {
      return this.$slots?.topLeft || this.$slots?.topRight || this.$slots?.topExpand || !!this.usedTopActions || this.versioned
    },
    ...mapGetters({
      userRights: 'auth/userRights',
      userScaledSize: 'auth/userScaledSize',
      userScaledMediumSize: 'auth/userScaledMediumSize',
      userScaledRatio: 'auth/userScaledRatio',
      userScaledRatioWidth: 'auth/userScaledRatioWidth',
      userScaledFontSize: 'auth/userScaledFontSize'
    }),
    crudEntity() {
      return this.entity || this.$route?.meta?.Entity || ''
    },
    excelExportItems() {
      const items = ['all', 'dev', 'prod']
      if (this.crudSettings.crudSelectIdsMode && this.crudSelectIdsLength) {
        items.push('selected')
      }
      return items
    },
    crudTableProps() {
      const props = { ...this.tableProps }
      delete props['row-class-name']
      return props
    }
  },
  watch: {},
  created() {
    this.init()
    this.fetchData = debounce(this.fetchData, 500)
    this.fetchData()
  },
  mounted() {
    this.calculateTopTableHeight()
  },
  methods: {
    init() {
      const queryParams = this.selectMode ? {} : this.$route?.query || {}
      // init sorting
      if (queryParams?.c_sor) {
        this.sorting.order = queryParams?.c_sor?.sort_desc === 'true' ? 'descending' : 'anscending'
        this.sorting.prop = queryParams?.c_sor?.sort || ''
      } else if (this.defaultSorting.column) {
        this.sorting.order = this.defaultSorting.desc ? 'descending' : 'anscending'
        this.sorting.prop = this.defaultSorting.column || ''
      }
      // init search fields
      if (queryParams.c_sea) {
        if (!this.searchFields) {
          this.searchFieldsModel = { ...this.searchFieldsModel, ...queryParams.c_sea }
        } else {
          const searchRefer = this.searchFields
          Object.keys(queryParams.c_sea).forEach((key) => {
            this.crudSearchFields[key] = queryParams.c_sea[key]
            searchRefer[key] = queryParams.c_sea[key]
          })
        }
      }
      this.$watch(
        'searchFieldsRefer',
        () => {
          this.fetchData()
        },
        { deep: true }
      )

      // init filterParams
      if (queryParams.c_fil) {
        this.crudFilters = { ...this.crudFilters, ...queryParams.c_fil }
      }
      this.$watch(
        'crudFilters',
        () => {
          if (this.apiFiltering) {
            this.fetchData()
          } else {
            this.crudUpdateQueryParams()
          }
        },
        { deep: true }
      )
      // init selectorVisibleColumns
      if (!this.selectMode) {
        this.$watch('selectorVisibleColumns.columns', () => {
          let isDefaultVisibleColumns = this.visibleColumns.length === this.selectorVisibleColumns.columns.length
          if (isDefaultVisibleColumns) {
            this.selectorVisibleColumns.columns.some((colName, index) => {
              if (this.visibleColumns?.[index] !== colName) {
                isDefaultVisibleColumns = false
                return true
              }
              return false
            })
          }
          this.updateCrudStorage(
            'selectorVisibleColumns',
            isDefaultVisibleColumns ? null : [...this.selectorVisibleColumns.columns],
            this.selectorVisibleColumns.initiated
          )
        })
      }
      const unwatch = this.$watch('watchedColumnsLength', () => {
        if (Object.keys(this.tableColumns)?.length) {
          this.selectorVisibleColumns.columns = (
            this.getCrudStorage('selectorVisibleColumns') ? this.getCrudStorage('selectorVisibleColumns') : this.visibleColumns
          ).filter((colApiName) => {
            return !!this.tableColumns?.[colApiName]
          })
          unwatch()
        }
      })
      // init crudSettings
      this.crudSettings = {
        ...this.crudSettings,
        ...this.defaultTableSettings,
        ...this.getCrudStorage('crudSettings'),
        ...this.getSessionCrudStorage('crudSettings')
      }
      // init pagination
      this.pagination.onPage = this.getCrudStorage('pagination') || this.rowsOnPageList?.[0] || this.rowsOnPage || 50
      if (queryParams?.c_pag) {
        this.pagination.currentPage = Number.parseInt(queryParams?.c_pag?.page || 1)
        this.pagination.onPage = Number.parseInt(queryParams?.c_pag?.on_page || this.rowsOnPageList?.[0] || 50)
        this.updateCrudStorage('pagination', this.pagination.onPage, false)
      }
      this.$watch('pagination.onPage', () => {
        this.updateCrudStorage('pagination', this.pagination.onPage)
        this.pagination.currentPage = 1
        if (this.apiPagination) {
          this.fetchData()
        } else {
          this.crudUpdateQueryParams()
        }
      })
      this.$watch('pagination.currentPage', () => {
        if (this.apiPagination) {
          this.fetchData()
        } else {
          this.crudUpdateQueryParams()
        }
      })
    },
    addHelperClass({ row, rowIndex }) {
      const helperClass = []
      if (this.versioned && row?.children?.length < 2) {
        helperClass.push('gs-ver-no-child')
      }
      if (this.groupBy && row?._nested !== undefined) {
        helperClass.push(`gs-nested gs-nested-${this.IndexGroupedBy?.[row?._nested?.groupedVal]}`)
        if (row?._nested?.parent) {
          helperClass.push('gs-nested-parent')
        }
        if (this.IndexGroupedBy?.[row?._nested?.groupedVal] % 2) {
          helperClass.push('gs-nested-odd')
        }
      }
      if (this.tableProps?.['row-class-name']) {
        const propHelperClass = this.tableProps['row-class-name']({ row, rowIndex })
        if (propHelperClass) {
          helperClass.push(propHelperClass)
        }
      }
      return helperClass.length ? helperClass.join(' ') : undefined
    }
  }
}
</script>

<template>
  <div
    v-loading="!!loadingActions?.crudAction_multi"
    class="gs-crud-wrapper relative"
    :class="[$attrs?.class ? undefined : 'pl-1.5 pr-0.5 text-sm sm:pl-4 sm:pr-4', $store.getters['auth/userScaledClass']]"
  >
    <!-- eslint-disable vue/no-unused-refs -->
    <div ref="topTableWrapper">
      <!-- top slots row -->
      <el-scrollbar class="relative">
        <div
          v-if="usedTopSlots"
          class="relative flex items-center pb-5 pt-2.5 leading-none sm:pb-1"
        >
          <template v-if="versioned && !selectMode && !hideVersionedTopFilters">
            <div class="mr-2 leading-none">
              <EnvFiltersTags
                v-model:search-fields="searchFieldsModel"
                label="Envs"
              />
            </div>
            <div class="leading-none">
              <AbSelector
                v-model="searchFieldsModel.ab"
                class="translate-y-px"
                :width-field-px="160"
                :size="$store.getters['auth/userScaledSize']"
              />
            </div>
          </template>
          <slot
            name="topLeft"
            :search-fields="crudSearchFields"
            :search-filters="crudFilters"
            :data="fetchDataResponse"
          />
          <div class="flex-1" />
          <slot
            name="topRight"
            :select-mode="selectMode"
          />
          <div
            v-if="versioned && !selectMode"
            class="whitespace-nowrap"
            :class="{ 'order-first': multiEntity }"
          >
            <!-- dev2ProdSyncSelected -->
            <el-button
              v-if="dev2ProdSyncSelected || multiEntity"
              :size="$store.getters['auth/userScaledMediumSize']"
              class="gs-btn-outlined-warning-light gs-loading gs-font-scaled mx-1 pl-1 pr-2 text-amber-500"
              :loading="loadingActions.dev2prod"
              :disabled="multiEntity && !dev2ProdSyncSelected"
              @click="syncItems('dev2prod')"
            >
              <el-icon class="gs-scaled-icon-xs mr-0.5">
                <Refresh />
              </el-icon> dev2Prod
            </el-button>
            <!-- maxId -->
            <el-tooltip
              v-if="maxId && $store.getters['auth/userRights']?.editor && !hideMaxId"
              effect="light"
              placement="top"
              content="next index"
              :show-after="600"
            >
              <el-button
                :size="$store.getters['auth/userScaledMediumSize']"
                class="gs-btn-outlined-warning-light gs-font-scaled relative mx-1 px-1"
                @click="copyMaxId"
              >
                <icon-ify
                  icon="tabler:click"
                  class="gs-scaled-icon-xs mr-0.5"
                />
                {{ maxId }}
              </el-button>
            </el-tooltip>
          </div>
          <div
            v-if="usedTopActions"
            class="whitespace-nowrap"
          >
            <!-- addNew -->
            <el-tooltip
              v-if="usedTopActions?.addNew && !selectMode"
              effect="light"
              placement="top"
              content="add new"
              :show-after="600"
            >
              <el-button
                v-if="!addNewSettings?.dropdownList?.length"
                :size="$store.getters['auth/userScaledMediumSize']"
                class="gs-btn-outlined-primary-light relative mx-1 px-1"
                @click="$router.push(crudPreviewAction())"
              >
                <icon-ify
                  icon="ic:outline-playlist-add"
                  class="gs-scaled-icon-lg"
                />
              </el-button>
              <el-dropdown v-else>
                <el-button
                  :size="$store.getters['auth/userScaledMediumSize']"
                  class="gs-btn-outlined-primary-light relative mx-1 px-1"
                >
                  <icon-ify
                    icon="ic:outline-playlist-add"
                    class="gs-scaled-icon-lg"
                  />
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="(listItem, index) in addNewSettings.dropdownList"
                      :key="index"
                      @click="$router.push($utils.bindStaticParams(listItem.route))"
                    >
                      <router-link
                        :to="listItem.route"
                        class="gs-font-scaled"
                      >
                        {{ listItem.label }}
                      </router-link>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-tooltip>
            <ExcelExportActionBtn />
            <!-- importExcel -->
            <el-tooltip
              v-if="usedTopActions?.importExcel && !selectMode"
              effect="light"
              placement="top"
              content="import"
              :show-after="600"
            >
              <el-button
                :size="$store.getters['auth/userScaledMediumSize']"
                class="gs-btn-outlined-success-light relative mx-1 mr-3 pl-1 pr-5"
                @click="visibleImportDialog = true"
              >
                <icon-ify
                  icon="file-icons:microsoft-excel"
                  class="gs-scaled-icon-xs"
                />
                <icon-ify
                  icon="iconoir:import"
                  class="absolute right-0.5 top-1 h-3.5 w-3.5"
                />
              </el-button>
            </el-tooltip>
            <ImportFromCsv
              v-if="!selectMode"
              v-model:visible="visibleImportDialog"
              :versioned="versioned"
              :entity="crudEntity"
              @imported="fetchData"
            />
          </div>
          <!-- slotExpand button -->
          <div v-if="$slots?.topExpand && !selectMode">
            <el-button
              class="gs-btn-outlined-primary-light ml-2 px-1 py-0 transition-all duration-500"
              size="small"
              :class="{
                'rotate-90': showTopExpand,
                'rotate-0': !showTopExpand
              }"
              @click.stop="showTopExpand = !showTopExpand"
            >
              <el-icon style="font-size: 20px">
                <Operation />
              </el-icon>
            </el-button>
          </div>
        </div>
        <icon-ify
          icon="mdi:gesture-swipe"
          class="gs-scaled-icon-xs absolute bottom-0 left-1/2 mr-0.5 text-neutral-400 sm:hidden"
        />
      </el-scrollbar>
      <!-- expand panel with topExpand slot -->
      <transition
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
      >
        <div
          v-show="showTopExpand && !selectMode"
          class="z-10 bg-white p-4 transition ease-in"
          :class="{
            'absolute left-0 right-0 p-top-[60px] shadow-md duration-200': crudSettings.fixed,
            'w-full duration-700': !crudSettings.fixed
          }"
        >
          <el-scrollbar
            v-if="crudSettings.fixed"
            max-height="400px"
          >
            <slot
              name="topExpand"
              :total="total"
              :params="{
                ...getSortingParams(),
                ...filterParams.all,
                ...searchParams.all,
                columns: currentTableVisibleColumnsNames.join(','),
                ...staticApiParams
              }"
              :search-fields="crudSearchFields"
              :search-filters="crudFilters"
            />
          </el-scrollbar>
          <slot
            v-else
            name="topExpand"
            :total="total"
          />
        </div>
      </transition>
      <div class="mt-1 border-b-2 border-neutral-50" />
      <slot name="topTable" />
    </div>
    <el-scrollbar
      v-if="$slots?.table"
      :height="tableHeight"
    >
      <slot
        name="table"
        :data="fetchDataResponse"
        :loading="tableLoader"
        :search-fields="crudSearchFields"
        :search-filters="crudFilters"
        :height="tableHeight"
      />
    </el-scrollbar>
    <template v-else>
      <!-- elementplus table -->
      <!-- eslint-disable vue/no-unused-refs -->
      <el-table
        ref="crudTable"
        :data="tableRows"
        :row-class-name="addHelperClass"
        v-bind="crudTableProps"
        class="gs-scaled"
        style="width: 100%"
        :style="[{ fontSize: $store.getters['auth/userScaledFontSize'] }]"
        :height="tableHeight"
        :fit="crudSettings.fit"
        :highlight-current-row="selectMode"
        :header-cell-class-name="tableProps?.['header-cell-class-name'] || 'border-b-4 border-neutral-50'"
        :empty-text="isAccess === false ? 'no permission' : tableLoader === undefined || tableLoader ? 'loading data' : 'no data'"
        :stripe="!!$store.getters['auth/userLocalSettings']?.stripedTables"
        :span-method="crudGroupSpanMethod"
        @cell-dblclick="showCellEditDialog"
        v-on="selectModeEvents"
      >
        <el-table-column
          v-if="enableNumberRows"
          type="index"
          label="#"
          label-class-name="pl-2"
        >
          <template #default="{ $index }">
            {{ $index + (pagination.currentPage - 1) * pagination.onPage + 1 }}
          </template>
        </el-table-column>
        <!-- grouped by -->
        <el-table-column
          v-if="groupBy"
          prop="grouped"
          :width="5"
        >
          <template #default="scope">
            <div
              v-if="scope.row?._nested?.parent"
              class="flex flex-row items-center px-2 py-3"
            >
              <icon-ify
                icon="bxs:right-arrow"
                class="gs-nested-icon cursor-pointer"
                @click="toggleGroupedRows(IndexGroupedBy?.[scope.row?._nested?.groupedVal])"
              />
              <div class="font-related-lg ml-2 leading-none">
                {{ scope.row?._nested?.groupedVal }}
                <sub>({{ scope.row?._nested?.counter }})</sub>
              </div>
            </div>
          </template>
        </el-table-column>
        <RowsMultiSelectColumn />
        <!-- selectMode column (single row; used by selectors) -->
        <el-table-column
          v-if="selectMode"
          fixed="left"
          width="50"
        >
          <template #default="{ row }">
            <div class="flex items-center justify-center">
              <el-icon
                v-if="currentSelected?.ID === row?.[selectedRowIndex]"
                class="gs-scaled-icon-xs scale-95 cursor-pointer text-sky-600 hover:scale-100"
              >
                <icon-ify
                  icon="mdi:checkbox-marked-outline"
                  class="h-full w-full"
                />
              </el-icon>
              <el-icon
                v-else
                class="gs-scaled-icon-xs scale-95 cursor-pointer text-neutral-400 hover:scale-100"
              >
                <icon-ify
                  icon="mdi:checkbox-blank-outline"
                  class="h-full w-full"
                />
              </el-icon>
            </div>
          </template>
        </el-table-column>
        <!-- expand col -->
        <el-table-column
          v-if="($slots?.expand_row || versioned) && !selectMode"
          type="expand"
          :width="25"
        >
          <template #default="scope">
            <slot
              name="expand_row"
              :index="scope.$index"
              :row="scope.row"
              :columns="tableColumns"
            >
              <template v-if="versioned">
                <CrudVersionsTable
                  v-model:dev2-prod-sync="dev2ProdSync"
                  :rows="scope.row.children"
                  :edit-route="
                    addNewSettings?.routeName
                      || (multiEntity && scope.row?.Entity ? $utils.getRouteByEntity(scope.row?.Entity, true)?.name : undefined)
                  "
                  :is-multiple-entity="multiEntity"
                />
              </template>
            </slot>
          </template>
        </el-table-column>
        <!-- cols -->
        <template
          v-for="(colData, index) in tableVisibleColumns"
          :key="colData.elAttr.prop + index"
        >
          <el-table-column v-bind="colData.elAttr">
            <!-- headers slots -->
            <template #header="scope">
              <div class="flex whitespace-nowrap">
                <!-- slot header_default -->
                <div
                  class="inline-block overflow-hidden text-ellipsis"
                  style="max-width: calc(100% - 10px)"
                >
                  <slot
                    v-if="$slots?.header_default && !$slots?.[`header_${scope.column.property}`]"
                    name="header_default"
                    :settings="colData"
                  >
                    <el-tooltip
                      v-if="colData.elAttr.width && scope.column.label.length > colData.elAttr.width / 13"
                      :content="scope.column.label"
                      effect="light"
                      placement="top"
                    >
                      {{ scope.column.label }}
                    </el-tooltip>
                    <template v-else>
                      {{ scope.column.label }}
                    </template>
                  </slot>
                  <!-- slot header_[colApiName] -->
                  <slot
                    v-else
                    :name="`header_${scope.column.property}`"
                    :settings="colData"
                  >
                    <el-tooltip
                      v-if="colData.elAttr.width && scope.column.label.length > colData.elAttr.width / 13"
                      :content="scope.column.label"
                      effect="light"
                      placement="top"
                    >
                      {{ scope.column.label }}
                    </el-tooltip>
                    <template v-else>
                      {{ scope.column.label }}
                    </template>
                  </slot>
                </div>
                <!-- slot if sortable -->
                <template v-if="colData?._sortable">
                  <!-- slot header_sort_default -->
                  <slot
                    v-if="!$slots?.[`header_sort_${scope.column.property}`]"
                    name="header_sort_default"
                    :settings="colData"
                    :sorting="sorting"
                    :on-sort="onSort"
                  >
                    <el-button
                      class="gs-btn-text-neutral-light gs-sort gs-font-scaled mr-1 px-0 hover:visible"
                      :class="{
                        visible: sorting.prop === scope.column.property,
                        invisible: sorting.prop !== scope.column.property
                      }"
                      :icon="
                        sorting.prop === scope.column.property
                          ? sorting.order === 'anscending'
                            ? icons.Top
                            : icons.Bottom
                          : icons.DCaret
                      "
                      size="small"
                      @click.stop="onSort(scope.column.property)"
                    />
                  </slot>
                  <!-- slot header_sort_[colApiName] -->
                  <slot
                    v-else
                    :name="`header_sort_${scope.column.property}`"
                    :settings="colData"
                    :sorting="sorting"
                    :on-sort="onSort"
                  />
                </template>
                <el-button
                  v-else
                  size="small"
                  class="invisible"
                />
              </div>
              <!-- header_search_default -->
              <slot
                v-if="!$slots?.[`header_search_${scope.column.property}`]"
                name="header_search_default"
                :settings="colData"
              >
                <div
                  v-if="colData?.filterField"
                  class="leading-none"
                >
                  <CrudTypeFields
                    v-model:value-field="crudFilters[getFilterFieldName(scope.column.property)]"
                    :type-value-field="colData.typeValueField"
                    :api-options="colData.typeApiColumn"
                    :prefix-icon="icons.Search"
                    :col-api-name="scope.column.property"
                  />
                </div>
              </slot>
              <!-- header_search_[colApiName] -->
              <slot
                v-else
                :name="`header_search_${scope.column.property}`"
                :settings="colData"
              />
              <div v-if="editAllColumn === colData.elAttr.prop && !selectMode">
                <el-button
                  :size="userScaledSize"
                  type=""
                  class="mr-1.5 mt-1.5"
                  @click="cancelCellEditDialog"
                >
                  cancel
                </el-button>
                <el-button
                  :size="userScaledSize"
                  class="gs-loading gs-btn-primary ml-0 mt-1.5"
                  type="primary"
                  :loading="loadingActions.editAllColumn"
                  @click="saveCellsInColumn(scope.column.property)"
                >
                  save all
                </el-button>
              </div>
            </template>
            <template #default="scope">
              <el-icon
                v-if="
                  tableColumns?.[scope.column.property]?._cellEdit
                    && !cellsEditFormShow[`${scope.row[indexColumn]}_${scope.column.property}`]
                    && !selectMode
                "
                class="gs-cell-edit-icon invisible absolute left-0 top-0 text-neutral-400"
                :size="8"
              >
                <EditPen />
              </el-icon>
              <el-tooltip
                v-if="
                  scope.column.property === 'ID'
                    && versioned
                    && scope.row?.Versioned?.IsDev === false
                    && scope.row?.Versioned?.IsProd === false
                "
                effect="light"
                :show-after="400"
                content="New duplicate (required update)"
                placement="top"
              >
                <icon-ify
                  icon="heroicons:document-duplicate"
                  class="h-4 w-4 gs-cell-edit-icon absolute top-0 right-0 text-fuchsia-500 cursor-help"
                />
              </el-tooltip>
              <div
                class="relative flex items-center"
                :class="[colData?.elAttr?.['class-name']]"
              >
                <div
                  class="relative inline-block"
                  style="max-width: 100%"
                >
                  <template v-if="!(editAllColumn && cellsEditFormShow[`${scope.row[indexColumn]}_${scope.column.property}`])">
                    <!-- slot cell_default -->
                    <slot
                      v-if="!$slots?.[`cell_${scope.column.property}`]"
                      name="cell_default"
                      :index="scope.$index"
                      :col-api-name="scope.column.property"
                      :value="scope.row[scope.column.property]"
                      :column-settings="colData"
                      :row="scope.row"
                    >
                      <CellContent
                        :col-data="colData"
                        :scope-col="scope"
                      />
                    </slot>
                    <!-- slot cell_[colApiName] -->
                    <slot
                      v-if="$slots?.[`cell_${scope.column.property}`]"
                      :name="`cell_${scope.column.property}`"
                      :index="scope.$index"
                      :col-api-name="scope.column.property"
                      :value="scope.row[scope.column.property]"
                      :column-settings="colData"
                      :row="scope.row"
                      :search-fields="crudSearchFields"
                      :search-filters="crudFilters"
                    />
                  </template>
                  <template v-if="cellsEditFormShow[`${scope.row[indexColumn]}_${scope.column.property}`]">
                    <!-- edit cell dialog (editAllColumn) -->
                    <template v-if="editAllColumn && !selectMode">
                      <!-- slot cell_edit_default (editAllColumn !!!) -->
                      <slot
                        v-if="!$slots?.[`cell_edit_${scope.column.property}`]"
                        name="cell_edit_default"
                        :index="scope.$index"
                        :col-api-name="scope.column.property"
                        :value="scope.row[scope.column.property]"
                        :column-settings="colData"
                        :row="scope.row"
                        :form="cellsEditFormFields"
                        :errors="cellsEditErrors"
                        :field-index="`${scope.row[indexColumn]}_${scope.column.property}`"
                      >
                        <div>
                          <CrudTypeFields
                            :id="scope.row?.ID || 0"
                            v-model:value-field="cellsEditFormFields[`${scope.row[indexColumn]}_${scope.column.property}`]"
                            :type-value-field="colData?.typeApiColumn?.editCellType || colData.typeValueField"
                            :api-options="colData.typeApiColumn"
                            full-width
                            :focus="scope.$index === 0"
                            :field="scope.column.property"
                            :tabindex="scope.$index + 100"
                            @change="clearError(`${scope.row[indexColumn]}_${scope.column.property}`)"
                            @dblclick.stop
                          />
                          <div
                            v-if="cellsEditErrors?.[`${scope.row[indexColumn]}_${scope.column.property}`]"
                            class="text-xs text-rose-400"
                          >
                            {{ cellsEditErrors[`${scope.row[indexColumn]}_${scope.column.property}`] }}
                          </div>
                        </div>
                      </slot>
                      <!-- slot cell_edit_[colApiName] (editAllColumn !!!) -->
                      <slot
                        v-if="$slots?.[`cell_edit_${scope.column.property}`]"
                        :name="`cell_edit_${scope.column.property}`"
                        :index="scope.$index"
                        :col-api-name="scope.column.property"
                        :value="scope.row[scope.column.property]"
                        :column-settings="colData"
                        :row="scope.row"
                        :form="cellsEditFormFields"
                        :field-index="`${scope.row[indexColumn]}_${scope.column.property}`"
                      />
                    </template>
                    <!-- edit cell dialog (Single cell) -->
                    <el-popover
                      v-else-if="!selectMode"
                      visible
                      placement="right"
                      :show-arrow="true"
                      :offset="5"
                      :width="220 + $store.getters['auth/userScaledWidth'] * 8"
                    >
                      <div
                        @keydown.enter.stop="saveCellEditDialog(scope.row, scope.column.property)"
                        @keydown.tab.prevent.stop="saveCellEditDialog(scope.row, scope.column.property, scope.$index)"
                        @keydown.esc.stop="cancelCellEditDialog"
                      >
                        <div class="my-3">
                          <!-- slot cell_edit_default -->
                          <slot
                            v-if="!$slots?.[`cell_edit_${scope.column.property}`]"
                            name="cell_edit_default"
                            :index="scope.$index"
                            :col-api-name="scope.column.property"
                            :value="scope.row[scope.column.property]"
                            :column-settings="colData"
                            :row="scope.row"
                            :form="cellsEditFormFields"
                            :field-index="`${scope.row[indexColumn]}_${scope.column.property}`"
                          >
                            <div>
                              <CrudTypeFields
                                :id="scope.row?.ID || 0"
                                v-model:value-field="cellsEditFormFields[`${scope.row[indexColumn]}_${scope.column.property}`]"
                                :type-value-field="colData?.typeApiColumn?.editCellType || colData.typeValueField"
                                :api-options="colData.typeApiColumn"
                                full-width
                                focus
                                :field="scope.column.property"
                                @change="clearError(`${scope.row[indexColumn]}_${scope.column.property}`)"
                              />
                            </div>
                          </slot>
                          <!-- slot cell_edit_[colApiName] -->
                          <slot
                            v-if="$slots?.[`cell_edit_${scope.column.property}`]"
                            :name="`cell_edit_${scope.column.property}`"
                            :index="scope.$index"
                            :col-api-name="scope.column.property"
                            :value="scope.row[scope.column.property]"
                            :column-settings="colData"
                            :row="scope.row"
                            :form="cellsEditFormFields"
                            :field-index="`${scope.row[indexColumn]}_${scope.column.property}`"
                          />
                          <div
                            v-if="cellsEditErrors?.[`${scope.row[indexColumn]}_${scope.column.property}`]"
                            class="text-xs text-rose-400"
                          >
                            {{ cellsEditErrors[`${scope.row[indexColumn]}_${scope.column.property}`] }}
                          </div>
                        </div>
                        <!-- slot cell_edit_actions_default -->
                        <slot
                          v-if="!$slots?.[`cell_edit_actions_${scope.column.property}`]"
                          name="cell_edit_actions_default"
                          :col-api-name="scope.column.property"
                          :value="scope.row[scope.column.property]"
                          :column-settings="colData"
                          :row="scope.row"
                          :cancel="
                            () => {
                              cancelCellEditDialog();
                            }
                          "
                          :confirm="
                            () => {
                              saveCellEditDialog(scope.row, scope.column.property);
                            }
                          "
                        >
                          <div
                            style="text-align: right; margin: 0"
                            class="relative"
                          >
                            <div class="absolute bottom-0 left-0">
                              <el-button
                                :size="userScaledSize"
                                class="gs-btn-text-success-light h-6 w-6 px-1"
                                :icon="icons.Finished"
                                @click="editCellsInColumn(scope.column.property)"
                              />
                            </div>
                            <el-button
                              :size="userScaledSize"
                              type=""
                              @click="cancelCellEditDialog"
                            >
                              cancel
                            </el-button>
                            <el-button
                              :size="userScaledSize"
                              class="gs-loading gs-btn-primary"
                              type="primary"
                              :loading="loadingActions[`${scope.row[indexColumn]}_${scope.column.property}`]"
                              @click="saveCellEditDialog(scope.row, scope.column.property)"
                            >
                              confirm
                            </el-button>
                          </div>
                        </slot>
                        <!-- slot cell_edit_actions_[colApiName] -->
                        <slot
                          v-if="$slots?.[`cell_edit_actions_${scope.column.property}`]"
                          :name="`cell_edit_actions_${scope.column.property}`"
                          :col-api-name="scope.column.property"
                          :value="scope.row[scope.column.property]"
                          :column-settings="colData"
                          :row="scope.row"
                          :cancel="
                            () => {
                              cancelCellEditDialog();
                            }
                          "
                          :confirm="
                            () => {
                              saveCellEditDialog(scope.row, scope.column.property);
                            }
                          "
                        >
                          <div style="text-align: right; margin: 0">
                            <el-button
                              size="small"
                              type=""
                              @click="cancelCellEditDialog"
                            >
                              cancel
                            </el-button>
                            <el-button
                              size="small"
                              class="gs-loading gs-btn-primary"
                              :loading="loadingActions[`${scope.row[indexColumn]}_${scope.column.property}`]"
                              type="primary"
                              @click="saveCellEditDialog(scope.row, scope.column.property)"
                            >
                              confirm
                            </el-button>
                          </div>
                        </slot>
                      </div>
                      <template #reference>
                        <div class="absolute bottom-0 right-0 top-0" />
                      </template>
                    </el-popover>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- Versions column -->
          <el-table-column
            v-if="versioned && colData.elAttr.prop === 'ID' && !selectMode && (dev2ProdSum || toRemoveOnProdSum)"
            :width="140 + 10 * userScaledRatioWidth"
          >
            <template #header>
              <div>
                Versions
                <el-button
                  size="small"
                  class="invisible"
                />
              </div>
              <ToggleAllSyncTags
                v-model:dev2-prod-sync="dev2ProdSync"
                :dev2-prod-sum="dev2ProdSum"
              />
              <SyncTags :summary-tag="{ '2removeOnProd': toRemoveOnProdSum }" />
            </template>
            <template #default="scope">
              <VersionsInfoTags
                v-if="versioned && scope.row?.children?.length > 1"
                :quantity="scope.row.versions"
                :children-envs="scope.row.childrenEnvs"
              />
              <SyncTags
                v-if="versioned && scope.row?.children?.length === 1"
                :versioned-data="scope.row.Versioned"
                :children-envs="scope.row.childrenEnvs"
                :dev2prod="dev2ProdSync[$utils.uniqRowIndex(scope.row.ID, scope.row?.Entity, multiEntity)]"
                @update:dev2prod="dev2ProdSync[$utils.uniqRowIndex(scope.row.ID, scope.row?.Entity, multiEntity)] = $event"
              />
            </template>
          </el-table-column>
          <!-- Img preview column (AdminIcon) -->
          <el-table-column
            v-if="showImgPreviewColumn && colData.elAttr.prop === 'ID'"
            :width="
              80 * userScaledRatioWidth
                + (35 * userScaledRatioWidth * $store.getters['auth/userLocalSettings']?.imgPreviewScale || 1) / 100
            "
            class-name="gs-cell-0 gs-col-td-padding-mini  gs-td-padding-mini"
            align="center"
          >
            <template #header>
              <div style="min-height: 50px">
                Preview
              </div>
            </template>
            <template #default="{ row }">
              <el-tooltip
                v-if="row.AdminIcon"
                raw-content
                :show-after="600"
                effect="light"
                placement="right-start"
              >
                <template #default>
                  <el-image
                    :src="`https://csndmapzfa.cloudimg.io/${row.AdminIcon.replace('https://', '')}?w=100`"
                    fit="contain"
                    :style="{
                      width: `${
                        35 * userScaledRatioWidth
                        + (35 * userScaledRatioWidth * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100
                      }px`,
                      height: `${
                        35 * userScaledRatioWidth
                        + (35 * userScaledRatioWidth * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100
                      }px`
                    }"
                    :preview-src-list="[row.AdminIcon]"
                    preview-teleported
                    hide-on-click-modal
                  />
                </template>
                <template #content>
                  <el-image
                    :src="`https://csndmapzfa.cloudimg.io/${row.AdminIcon.replace('https://', '')}?w=200`"
                    fit="contain"
                    style="width: 200px; height: 200px"
                  />
                </template>
              </el-tooltip>
            </template>
          </el-table-column>
        </template>
        <!-- action col -->
        <el-table-column
          v-if="actionColSettings !== false && tableActionsListArr?.length && tableRows?.length && !selectMode"
          v-bind="bindActionsColAttr"
        >
          <template #header>
            <div class="truncate">
              Actions
            </div>
          </template>
          <template #default="scope">
            <slot
              name="cell_action"
              :row="scope.row"
              :list="tableActionsList"
              :loading="loadingActions"
              :trigger="triggerBuiltInActions"
            >
              <template
                v-for="(actionData, actionApiName, index) in tableActionsList"
                :key="index"
              >
                <el-tooltip
                  v-if="actionData?.withTooltip"
                  :content="actionData?.label"
                  effect="light"
                  placement="top"
                  :show-after="600"
                >
                  <router-link
                    v-if="actionData?.link"
                    :to="triggerBuiltInActions(actionApiName, scope.row)"
                    tabindex="-1"
                  >
                    <el-button
                      :loading="loadingActions[`${actionApiName}_${scope.row[indexColumn]}`]"
                      class="gs-height-related-sm font-related-xl px-1"
                      v-bind="actionData?.elAttr"
                      tabindex="-1"
                    />
                  </router-link>
                  <el-button
                    v-else
                    :loading="loadingActions[`${actionApiName}_${scope.row[indexColumn]}`]"
                    class="gs-height-related-sm font-related-xl px-1"
                    v-bind="actionData?.elAttr"
                    tabindex="-1"
                    @click="triggerBuiltInActions(actionApiName, scope.row)"
                  />
                </el-tooltip>
                <template v-else>
                  <router-link
                    v-if="actionData?.link"
                    :to="triggerBuiltInActions(actionApiName, scope.row)"
                    tabindex="-1"
                  >
                    <el-button
                      :loading="loadingActions[`${actionApiName}_${scope.row[indexColumn]}`]"
                      class="gs-height-related-sm font-related-xl px-1"
                      v-bind="actionData?.elAttr"
                      tabindex="-1"
                    />
                  </router-link>
                  <el-button
                    v-else
                    :loading="loadingActions[`${actionApiName}_${scope.row[indexColumn]}`]"
                    class="gs-height-related-sm font-related-xl px-1"
                    v-bind="actionData?.elAttr"
                    tabindex="-1"
                    @click="triggerBuiltInActions(actionApiName, scope.row)"
                  />
                </template>
              </template>
            </slot>
            <slot
              name="cell_rowAction"
              :row="scope.row"
            />
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- after table -->
    <div
      v-if="isAccess && !hideBottomActionBar"
      class="relative flex flex-1 py-2"
      :class="{
        'justify-end border-t-4 border-neutral-50': crudSettings.fit,
        'justify-start': !crudSettings.fit
      }"
    >
      <el-progress
        v-show="tableLoader"
        class="absolute -top-1 left-0 right-0"
        :percentage="100"
        color="#0e7490"
        :stroke-width="2"
        :show-text="false"
        :indeterminate="tableLoader"
        :duration="1"
      />
      <div
        v-if="$windowWidth > 640"
        class="flex flex-1 items-center justify-start"
      >
        <slot
          name="bottomBarLeft"
          :data="fetchDataResponse"
          :loading="tableLoader"
        />
      </div>
      <!-- clear all filters -->
      <el-button
        v-show="showClearBtn"
        class="gs-btn-text-neutral-light mr-2 h-auto px-0 py-0"
        @click="clearFilters"
      >
        <el-tooltip
          content="Reset all"
          effect="light"
          placement="top"
          :show-after="600"
        >
          <div>
            <el-icon class="gs-scaled-icon-xs">
              <CircleClose />
            </el-icon>
          </div>
        </el-tooltip>
      </el-button>
      <!-- refresh data -->
      <el-button
        v-if="!hideRefreshBtn"
        class="gs-btn-text-neutral-light ml-2 h-auto p-0"
        :loading="tableLoader"
        @click="fetchData"
      >
        <el-tooltip
          content="Refresh data"
          effect="light"
          placement="top"
          :show-after="600"
        >
          <div>
            <icon-ify
              v-show="!tableLoader"
              icon="ic:baseline-refresh"
              class="gs-scaled-icon-xs"
            />
          </div>
        </el-tooltip>
      </el-button>
      <!-- pagination -->
      <slot name="pagination">
        <el-pagination
          v-if="!disablePagination"
          v-model:current-page="pagination.currentPage"
          v-model:page-size="pagination.onPage"
          :page-sizes="rowsOnPageList"
          :size="userScaledRatio < 1.1 ? 'small' : 'default'"
          :layout="`prev ${$windowWidth >= 640 ? ', pager' : ''}, next, total, ${hidePaginationSize ? '' : ', sizes'}`"
          :total="crudTotal"
        />
      </slot>
      <!--  columns selector -->
      <CrudSelectorsColumns v-if="!hideColumnsSelector" />
      <!--  crud settings -->
      <CrudSettingsBox v-if="!selectMode && !hideSettingsSelector" />
    </div>
    <!-- drawers slots -->
    <slot
      name="drawers"
      :refresh-data="fetchData"
      :show-top-expand="
        (show) => {
          showTopExpand = show;
        }
      "
    />
    <SyncErrorsDialog v-if="syncErrorsDialog" />
    <ApiValidationWarningsDialog v-if="apiValidationWarningsDialog" />
  </div>
</template>

<style lang="postcss">
.gs-crud-wrapper {
  .el-table {
    th {
      vertical-align: top !important;
    }

    th:hover {
      .gs-sort {
        visibility: visible !important;
      }
    }

    td:hover {
      .gs-cell-edit-icon.invisible {
        visibility: visible !important;
      }
    }

    .el-table__expand-column .cell {
      padding: 0 !important;
    }

    .el-table__expanded-cell {
      padding: 0 !important;
    }

    .el-table__body tr.hover-row > td {
      background-color: transparent !important;
    }

    .el-table__body tr:hover > td {
      background-color: #eeeeee !important;
    }

    td.gs-cell-0 .cell {
      line-height: 0;
    }
  }
  .gs-ver-no-child {
    .el-table__expand-icon {
      display: none !important;
    }
  }
  &.gs-scaled- {
    &xl,
    &lg {
      .el-tag__content {
        font-size: 16px;
      }
    }
  }
  .gs-nested {
    &:not(.gs-nested-parent) {
      &:not(.gs-expanded),
      &:not(.gs-expanded) + tr:not(.gs-nested) {
        display: none;
      }
    }
    &-parent {
      td,
      div.cell {
        padding: 0;
      }
      font-weight: bolder;
      &.gs-nested-odd {
        background-color: rgba(220, 252, 231, 0.5);
        td.el-table-fixed-column--left {
          background-color: rgba(220, 252, 231, 0.5) !important;
        }
      }
      &:not(.gs-nested-odd) {
        background-color: rgba(219, 234, 254, 0.5);
        td.el-table-fixed-column--left {
          background-color: rgba(219, 234, 254, 0.5) !important;
        }
      }
      &.gs-expanded {
        .gs-nested-icon {
          transform: rotate(90deg);
        }
      }
    }
  }
}
</style>
