<script>
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import { mapGetters, useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useCssVar, useTitle } from '@vueuse/core'
import {
  CaretLeft,
  CircleCheck,
  Coin,
  CopyDocument,
  Delete,
  Edit,
  InfoFilled,
  Loading,
  Memo,
  Plus,
  View
} from '@element-plus/icons-vue'
import { merge } from 'lodash'
import { DefaultMeta } from '@/settings/defaultMeta'
import Breadcrumb from '!/components/shared/Breadcrumb.vue'
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'
import { userRightRoles } from '!/composition/utilities'
import { globalProperties as app } from '!/plugins/utilities'

const builtInActionsSettings = {
  crudSave: {
    label: 'save',
    link: false,
    right: userRightRoles.editor,
    elAttr: { /* type: '', */ class: 'gs-btn-primary ml-2', icon: CircleCheck }
  },
  crudDuplicate: {
    label: 'duplicate',
    link: false,
    right: userRightRoles.editor,
    elAttr: { /* type: '', */ class: 'gs-btn-outlined-warning-neutral ml-2', icon: CopyDocument }
  },
  crudNewVersion: {
    label: 'new version',
    link: false,
    right: userRightRoles.editor,
    elAttr: { /* type: '', */ class: 'gs-btn-outlined-success-neutral ml-2', icon: Coin }
  },
  crudUsage: {
    label: 'usage',
    link: false,
    right: userRightRoles.editor,
    elAttr: { /* type: '', */ class: 'gs-btn-outlined-warning-neutral ml-2', icon: View }
  },
  crudLogs: {
    label: 'logs',
    link: false,
    right: userRightRoles.editor,
    elAttr: { /* type: '', */ class: 'gs-btn-outlined-warning-neutral ml-2', icon: Memo }
  },
  crudDelete: {
    label: 'delete',
    right: userRightRoles.editor,
    elAttr: { /* type: '', */ class: 'gs-btn-outlined-danger-neutral ml-2', icon: Delete }
  }
}

export default {
  name: 'CrudDetailsPage',
  components: {
    Breadcrumb,
    CrudDetailsField: defineAsyncComponent(() => import('!/components/crud/sub/CrudDetailsField.vue')),
    AbSelector: defineAsyncComponent(() => import('!/components/selectors/AbSelector.vue')),
    ApplyItemTagsDialog: defineAsyncComponent(() => import('!/components/crud/sub/ApplyItemTagsDialog.vue')),
    UsageDialog: defineAsyncComponent(() => import('!/components/crud/sub/UsageDialog.vue')),
    LogsDialog: defineAsyncComponent(() => import('!/components/crud/sub/LogsDialog.vue')),
    SyncErrorsDialog: defineAsyncComponent(() => import('!/components/crud/sub/SyncErrorsDialog.vue')),
    ApiValidationWarningsDialog: defineAsyncComponent(() => import('!/components/crud/sub/ApiValidationWarningsDialog.vue')),
    Loading,
    InfoFilled,
    MoreActions: defineAsyncComponent(() => import('!/components/crud/sub/CrudDetailsMoreActions.vue'))
  },
  provide() {
    return {
      crudForm: computed(() => this.form),
      initForm: computed(() => this.initForm),
      crudFormRefer: computed(() => this.$refs.editForm),
      crudExternalErrors: computed(() => this.externalErrors),
      crudDetails: this,
      enableRelations: this.enableRelations,
      crudRelations: computed(() => this.form?._Relations),
      crudMultiChangesMode: this.crudMultiChangesMode
    }
  },
  props: {
    /** ** api */
    api: {
      type: String,
      default: ''
    },
    apiPrefix: {
      type: String,
      default: '/admin/api/'
    },
    entity: {
      type: String,
      default: ''
    },
    /**
     *  default "item" field of api response is passed to the edit form, that is available as "form" reference (apiResponse.item corresponds to the reference "form")
     *  if there is a need to add other fields of api response to reference "form" they must be mentioned in this prop;
     *
     *  if element this prop is string data are added under the key of the same name eg. ['rows'] means form.rows
     *  if element this prop is object the key of this object is name of field api response and value this object is name of key added under to form;
     *            eg [{rows: '_rows_'}] means form._rows_
     *
     *  important: "item" key cannot be overwritten; the form reference is always a reference to apiResponse.item and another fields of api response
     *  which was indicated in a given prop
     */
    apiFormKeys: {
      type: Array,
      default: () => ['rows']
    },
    /**
     *  modification of loaded item data before connecting to the form
     *    (eg if the structure of the form is different from the api data);
     *  must return data object;
     */
    renderInitItem: {
      type: Function,
      default(loadData) {
        return loadData
      }
    },
    /**
     * modify data before send by api (before save)
     *    if the structure of the form is insufficient (need to add additional fields; modify the data returned by the form's components);
     *    must return data object;
     */
    renderSavedItem: {
      type: Function,
      default(savedData) {
        return savedData
      }
    },
    renderDuplicateItem: {
      type: Function,
      default(duplicateData) {
        return duplicateData
      }
    },
    renderVersionedItem: {
      type: Function,
      default(versionedData) {
        return versionedData
      }
    },
    /** ** config */
    backRoutes: {
      type: Array,
      default: () => []
    },
    renderTitle: {
      type: Function,
      default(apiLabel /* , apiData */) {
        return apiLabel
      }
    },
    accessRight: {
      type: String,
      default: userRightRoles.user
    },
    fixedActions: {
      type: Boolean,
      default: true
    },
    actions: {
      type: Object,
      default: () => ({
        // '[crud]NameAction' : {settings object}; actions whose name begins with  'crud' when clicked, they call the built-in method with the same name
        // it is merged with builtInActionsSettings (definition above default export)
      })
    },
    disableActions: {
      type: Object,
      default: () => ({
        // '[crud]NameAction' : boolean (it allow manage disabled prop actions)
      })
    },
    reLoadDataAfterSave: {
      type: Boolean,
      default: false
    },
    versioned: {
      // show versioned components
      type: Boolean,
      default: true
    },
    entityRoute: {
      // force route used by this component,  used when this component (page) is imported in modal;
      type: Object,
      default: undefined
    },
    hidePreviewImg: {
      type: Boolean,
      default: false
    },
    formClass: {
      type: String,
      default: undefined
    },
    formClassModalMode: {
      type: String,
      default: 'mt-4'
    },
    disableTopMargin: {
      type: Boolean,
      default: false
    },
    forceAllVersioned: {
      type: Boolean,
      default: false
    },
    externalData: {
      type: Object,
      default: undefined
    },
    enableRelations: {
      type: Boolean,
      default: false
    },
    /**
     * key used by api stored documentation;
     * default docKey === entity
     */
    docKey: {
      type: String,
      default: undefined
    },
    disableDoc: {
      type: Boolean,
      default: false
    },
    proxy: {
      type: Boolean,
      default: false
    },
    mobileSingleColumn: {
      type: Boolean,
      default: true
    },
    disableDuplicationDiff: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change-meta-data', 'cancel', 'change', 'loaded-data', 'is-access'],
  setup(props, context) {
    const isModalMode = props.entityRoute !== undefined
    const store = useStore()
    const route = isModalMode ? props.entityRoute : useRoute()
    const duplicatedMode = ref(false)
    const newVersionMode = ref(false)
    const metaData = ref({
      title: '',
      subtitle: '',
      imgUrl: '',
      hideImg: props.hidePreviewImg,
      action: 'edit'
    })
    if (!isModalMode) {
      onMounted(() => {
        const actionsBarHeight = useCssVar('--gs-actions-bar-height', document.documentElement)
        actionsBarHeight.value = 'calc(1.5 * var(--gs-bar-height) + var(--gs-size-font) - 10px)'
      })
    }

    const saveMetaData = () => {
      if (isModalMode) {
        const emitMetaData = {
          ...metaData.value,
          subtitle:
            (route?.meta?.PageHeaderTitle || route?.meta?.Title) +
            (metaData.value.action === 'edit' ? ' edit' : '') +
            metaData.value.subtitle
        }
        context.emit('change-meta-data', emitMetaData)
      } else {
        store.commit('main/pageHeaderSubtitle', metaData.value.subtitle)
        store.commit('main/pageHeaderImgUrl', metaData.value.imgUrl)
        store.commit('main/pageHeaderHideImg', metaData.value.hideImg)
      }
    }

    watch(
      metaData.value,
      () => {
        saveMetaData()
      },
      { deep: true, immediate: true }
    )

    onUnmounted(() => {
      metaData.value.subtitle = ''
      metaData.value.imgUrl = ''
      metaData.value.hideImg = false
      if (!isModalMode) {
        const actionsBarHeight = useCssVar('--gs-actions-bar-height', document.documentElement)
        actionsBarHeight.value = '0px'
      }
      saveMetaData()
    })

    const id = ref(Number.parseInt(route.params.id))
    if (route.query?.duplicate && id.value === 0 && Number.parseInt(route.query.duplicate)) {
      id.value = Number.parseInt(route.query.duplicate)
      duplicatedMode.value = true
    } else if (route.query?.new_version_of && id.value === 0 && Number.parseInt(route.query.new_version_of)) {
      id.value = Number.parseInt(route.query.new_version_of)
      newVersionMode.value = true
    }

    let crudMultiChangesMode = false
    if (route?.query?.cdwe?.length || route?.query?.cme?.length) {
      crudMultiChangesMode = true
    }
    metaData.value.action = id.value === 0 ? 'add new' : 'edit'

    const updateTitle = (apiLabel, apiData = null, renderTitle = true) => {
      setTimeout(() => {
        // because setMeta watcher works;
        const renderedTitle = renderTitle ? props.renderTitle(apiLabel, apiData) : apiLabel
        if (renderedTitle) {
          metaData.value.title =
            `${renderedTitle.charAt(0).toUpperCase() +
            renderedTitle.slice(1)
            } : ${
              DefaultMeta?.Title || route?.meta?.Title || route?.name
            }${DefaultMeta?.TitleSuffix || ''}`
          if (!isModalMode) {
            useTitle(metaData.value.title)
          }
          metaData.value.subtitle = `${route?.meta?.Title ? ' :' : ''} ${renderedTitle}`
        }
      }, 0)
    }

    if (!id.value) {
      updateTitle(metaData.value.action, null, false)
    }

    const actionsSettings = merge({}, builtInActionsSettings, props.actions)
    const isVersionedAccess = app.$utils.checkRights(actionsSettings?.crudNewVersion?.right || userRightRoles.editor)

    return {
      id,
      isModalMode,
      metaData,
      updateTitle,
      icons: { Edit, CopyDocument },
      envSettings: CrudTagsSettings(['dev', 'prod']),
      checkEditUserInterval: null,
      lastActivity: 0,
      actionsSettings,
      isVersionedAccess,
      duplicatedMode,
      newVersionMode,
      crudMultiChangesMode
    }
  },
  data() {
    return {
      loading: {},
      form: {},
      initForm: {
        form: {},
        diffs: {}
      },
      externalErrors: {},
      initiated: false,
      usageDialog: false,
      logsDialog: false,
      versionedData: {},
      currentActionDescription: '',
      editingUsers: [],
      isAccess: undefined,
      formRefer: null,
      watcherRelations: null,
      applyItemsTagsDialog: false,
      syncErrorsDialog: false,
      syncErrorsDetails: {
        sync_error: [],
        syncForced: false,
        syncRefers: false
      },
      apiValidationWarningsDialog: false,
      apiValidationWarningsDetails: {
        warnings: [],
        skip: false
      },
      docMode: false,
      drawerDocumentation: false,
      docs: {},
      instanceNotes: '',
      currentDocData: {},
      userValidationWarnings: {},
      proxyApHost: '',
      modalModeEntity: ''
    }
  },
  computed: {
    cancelAction() {
      return {
        label: 'back',
        name: 'crudCancel',
        link: false,
        elAttr: { /* type: '', */ class: 'gs-btn-outlined-neutral pl-1', icon: CaretLeft }
      }
    },
    actionsList() {
      if (this.id) {
        const actionsForEditItem = Object.fromEntries(
          Object.entries(this.actionsSettings).filter(([, actionSettings]) => {
            return actionSettings !== false && this.$utils.checkRights(actionSettings?.right || userRightRoles.editor)
          })
        )
        if (this.versioned) {
          if (this.form?.Versioned?.IsProd) {
            delete actionsForEditItem.crudDelete
          }
        } else {
          delete actionsForEditItem.crudNewVersion
        }
        return actionsForEditItem
      } else {
        const actionsForAddItem = {}
        if (
          this.actionsSettings?.crudSave &&
          this.$utils.checkRights(this.actionsSettings.crudSave.right || userRightRoles.editor)
        ) {
          actionsForAddItem.crudSave = this.$utils.cloneDeep(this.actionsSettings.crudSave)
          actionsForAddItem.crudSave.label = this.currentActionDescription || 'save new item'
          actionsForAddItem.crudSave.elAttr.icon = Plus
          if (!this.isModalMode && !this.currentActionDescription) {
            actionsForAddItem.crudSaveMany = this.$utils.cloneDeep(this.actionsSettings.crudSave)
            actionsForAddItem.crudSaveMany.label = 'save and add next'
            actionsForAddItem.crudSaveMany.elAttr.icon = Plus
          }
          if (this.isModalMode && this.duplicatedMode && this.actionsSettings?.crudSave) {
            actionsForAddItem.crudSave.label += ' and choose'
          }
        }
        return actionsForAddItem
      }
    },
    actionsListArr() {
      return (
        Object.entries(this.actionsList).map(([actionName, actionConfig]) => {
          return { ...actionConfig, prop: actionName }
        }) || []
      )
    },
    crudEntity() {
      return this.entity || this.modalModeEntity || this.$route?.meta?.Entity || ''
    },
    ...mapGetters({
      pageHeaderSubtitle: 'main/pageHeaderSubtitle',
      userRights: 'auth/userRights'
    }),
    crudApiFormKeys() {
      return this.apiFormKeys.map((element) => {
        if (typeof element === 'string') {
          return { [element]: element }
        } else {
          return element
        }
      })
    },
    crudBackRoutes() {
      if (!this.backRoutes.length && this.$route.name.slice(-8) === '-details') {
        return [this.$route.name.slice(0, -8)]
      }
      return this.backRoutes
    },
    initFormDiffsCounter() {
      return Object.keys(this.initForm.diffs).length
    },
    crudDocKey() {
      return this.docKey || this.crudEntity || this.$route.name
    },
    userValidationArr() {
      return Object.entries(this.userValidationWarnings).filter(([, error]) => !!error)
    }
  },
  created() {
    if (this.externalData !== undefined) {
      this.$watch('externalData', () => {
        this.initRelations(this.externalData)
        this.form = this.externalData
        this.updateTitle(null, this.form)
        this.showForm()
      })
    }
    this.loadDocumentation()
    this.loadInstanceNotes()
    this.$watch('id', () => {
      this.instanceNotes = ''
      this.loadInstanceNotes()
    })
    if (this.proxy) {
      this.proxyApHost = this.$store.getters['auth/envs'][this.$route?.query?.env] || ''
    }
  },
  mounted() {
    this.loadData()
    document.addEventListener('mousedown', this.markLastActivity, false)
    document.addEventListener('keydown', this.markLastActivity, false)
    this.initCheckEditUsers()
    this.checkEditUsers()
  },
  beforeUnmount() {
    clearInterval(this.checkEditUserInterval)
    document.removeEventListener('mousedown', this.markLastActivity, false)
    document.removeEventListener('keydown', this.markLastActivity, false)
    if (this.$store.state.auth.token) {
      this.$axios
        .post(`${this.proxyApHost + this.apiPrefix}open-form/close/`, {
          key: this.editingUsersKey
        })
        .catch(this.$utils.catchError)
    }
  },
  methods: {
    loadData() {
      this.modalModeEntity = ''
      this.isAccess = this.$utils.checkRights(this.accessRight || userRightRoles.user)
      this.notifyAccessDanied(this.isAccess)
      if (this.isAccess) {
        this.$emit('is-access')
        if (!this.api || this.externalData !== undefined)
          return false
        if (this.id) {
          this.$axios
            .get(`${this.apiPrefix + this.api}/${this.id}/`)
            .then((result) => {
              if (this.isModalMode) {
                this.modalModeEntity = result?.data?.entity || ''
              }
              this.crudApiFormKeys.forEach((element) => {
                const [apiKey, formKey] = Object.entries(element)[0]
                if (apiKey !== 'item' && result?.data?.[apiKey] !== undefined) {
                  if (result?.data?.item?.[formKey] === undefined) {
                    result.data.item[formKey] = result.data[apiKey]
                  } else {
                    throw new Error('conflict api forms keys')
                  }
                }
              })
              this.form = result?.data?.item || {}
              const loadedDataItem = this.renderInitItem(this.form)
              this.updateTitle(result?.data?.admin?.label, loadedDataItem)
              this.metaData.imgUrl = result?.data?.admin?.icon || ''
              this.initRelations(loadedDataItem)
              this.form = loadedDataItem
              if (this.versioned) {
                this.versionedData = {
                  admin_root: result?.data?.admin_root || {},
                  root: result?.data?.root || {},
                  children: result?.data?.children || []
                }
              }
              this.showForm()
              if (this.duplicatedMode) {
                this.crudDuplicateAction()
              } else if (this.newVersionMode) {
                this.crudNewVersionAction()
              }
              this.$utils.nextLoopEvent().then(() => {
                this.$emit('loaded-data', this.form)
              })
            })
            .catch(this.$utils.catchError)
        } else {
          this.showEmptyForm()
          this.$emit('loaded-data', this.form)
        }
      }
    },
    loadDocumentation() {
      this.$axios
        .get(`${this.apiPrefix}documentation/get/`, {
          params: {
            form_id: this.crudDocKey
          }
        })
        .then(({ data }) => {
          if (data?.rows) {
            const renderedData = {}
            data.rows.forEach((row) => {
              renderedData[row.Location] = { ...JSON.parse(row.Text) }
            })
            this.docs = renderedData
          }
        })
        .catch(this.$utils.catchError)
    },
    loadInstanceNotes() {
      if (this.id) {
        this.$axios
          .get(`${this.apiPrefix}documentation/get/`, {
            params: {
              form_id: `${this.crudDocKey}_notes_${this.id}`
            }
          })
          .then(({ data }) => {
            if (data?.rows?.[0]?.Location === 'instanceNotes') {
              this.instanceNotes = data.rows[0].Text || ''
            }
          })
          .catch(this.$utils.catchError)
      }
    },
    initRelations(data) {
      if (this.enableRelations) {
        data._Relations = {}
        data._RelationsLength = 0
        if (this.watcherRelations === null) {
          this.watcherRelations = this.$watch(
            'form._Relations',
            () => {
              const itemTags = []
              data._RelationsLength = 0
              Object.entries(this.form._Relations).forEach(([key, val]) => {
                if (val) {
                  ++data._RelationsLength
                  itemTags.push(`${key}:${val}`)
                }
              })
              this.form.ItemTags = itemTags.join('\n')
            },
            { deep: true }
          )
        }
        if (data.ItemTags !== undefined) {
          data._Relations = Object.fromEntries(
            data.ItemTags.split('\n')
              .map(val => val.split(':'))
              .filter(val => !!val[0].length)
          )
        }
      }
    },
    showForm() {
      this.$utils.nextLoopEvent().then(() => {
        this.initiated = true
      })
      this.$utils.nextLoopEvent().then(() => {
        this.$refs?.editForm?.clearValidate?.()
        this.formRefer = this.$refs?.editForm
        this.initForm.form = merge({}, this.form)
        this.initForm.diffs = {}
      })
    },
    showEmptyForm() {
      if (this.versioned) {
        this.form = {
          Versioned: {
            IsDev: true,
            IsProd: this.forceAllVersioned,
            Root: 0
          }
        }
      }
      this.form = this.renderInitItem(this.form)
      this.initRelations(this.form)
      this.showForm()
    },
    notifyAccessDanied(access) {
      // el+ BUG (not working prop icon (icon: 'warning-filled')
      // https://element-plus.run/#eyJBcHAudnVlIjoiPHRlbXBsYXRlPlxuICA8ZWwtYnV0dG9uIHBsYWluIEBjbGljaz1cInRlc3RcIj4gVGVzdCBpY29uIDwvZWwtYnV0dG9uPlxuPC90ZW1wbGF0ZT5cblxuPHNjcmlwdCBsYW5nPVwidHNcIiBzZXR1cD5cbmltcG9ydCB7IEVsTm90aWZpY2F0aW9uIH0gZnJvbSAnZWxlbWVudC1wbHVzJ1xuXG5jb25zdCB0ZXN0ID0gKCkgPT4ge1xuICBFbE5vdGlmaWNhdGlvbih7XG4gICAgdGl0bGU6ICdUaXRsZScsXG4gICAgaWNvbjogJ3dhcm5pbmctZmlsbGVkJyxcbiAgICBtZXNzYWdlOiAndGVzdG93bycsXG4gIH0pXG59XG48L3NjcmlwdD5cbiIsImltcG9ydF9tYXAuanNvbiI6IntcbiAgXCJpbXBvcnRzXCI6IHt9XG59IiwiX28iOnt9fQ==
      if (!access) {
        this.$notify({
          title: 'No permission',
          type: 'warning',
          customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
          message: 'access denied'
        })
      }
    },
    triggerBuiltInActions(actionName) {
      if (actionName.slice(0, 4) === 'crud' && this.$options.methods?.[`${actionName}Action`]) {
        return this[`${actionName}Action`]()
      }
    },
    crudCancelAction() {
      if (this.initFormDiffsCounter) {
        this.$messageBox
          .confirm('Form data has been changed. Do you definitely want to exit without saving the data?', undefined, {
            confirmButtonText: 'Yes, exit',
            type: 'warning'
          })
          .then(() => {
            this.crudCancelActionConfirmed()
          })
      } else {
        this.crudCancelActionConfirmed()
      }
    },
    crudCancelActionConfirmed() {
      if (this.isModalMode) {
        this.$emit('cancel')
        return false
      }
      if (this.$route?.meta?.forcedId) {
        this.$router.push({ name: 'dashboard' })
      } else if (this.crudBackRoutes?.length || this.$route.query?.back?.length) {
        const backRoute = this.$utils.getFullRouteData(
          this.$route.query?.back || this.crudBackRoutes[this.crudBackRoutes.length - 1]
        )
        const backHistory = this.$router?.options?.history?.state?.back
          ? this.$utils.getFullRouteData({ path: this.$router.options.history.state.back })
          : {}
        if (backHistory?.name && (backRoute?.meta?.IdParentSubmenu || backRoute?.name === backHistory?.name)) {
          this.$router.go(-1) // route from admin panel
        } else {
          this.$router.push(backRoute)
        }
      } else {
        this.$router.go(-1)
      }
    },
    crudSaveAction(next = false) {
      if (this.userValidationArr.length && !this.apiValidationWarningsDetails.skip && !this.syncErrorsDetails.syncForced) {
        this.$messageBox
          .confirm('Are you sure ? Some fields failed user validation (set in field documentation).', undefined, {
            confirmButtonText: 'Yes, continue',
            type: 'warning'
          })
          .then(() => {
            this.crudSaveActionConfirmed(next)
          })
      } else {
        this.crudSaveActionConfirmed(next)
      }
    },
    crudCdweAction() {
      const ids = (this.$route?.query?.cdwe ?? '').split(',')
      if (!this.crudMultiChangesMode || ids.length === 0) {
        return false
      }
      const savedData = this.renderSavedItem(this.$utils.cloneDeep(this.form))
      if (savedData.Versioned) {
        delete savedData.Versioned
      }
      this.$refs?.editForm
        ?.validate?.()
        .then(() => {
          this.loading.cdwe = true
          const params = { ids: ids.map(id => id * 1), entity: this.crudEntity, form: savedData, diffs: Object.keys(this.initForm.diffs) }

          this.$axios
            .post(`${this.apiPrefix}entities/duplicate/`, params)
            .then(() => {
              this.crudCancelActionConfirmed()
            })
            .catch(this.$utils.catchError)
            .then(() => {
              this.loading.cdwe = false
            })
        })
        .catch((err) => {
          this.loading.cdwe = false
          this.$utils.notifyNotAllValid(err)
        })
    },
    crudCmeAction() {
      const ids = (this.$route?.query?.cme ?? '').split(',')
      if (!this.crudMultiChangesMode || ids.length === 0) {
        return false
      }
      const savedData = this.renderSavedItem(this.$utils.cloneDeep(this.form))
      if (savedData.Versioned) {
        delete savedData.Versioned
      }
      this.$refs?.editForm
        ?.validate?.()
        .then(() => {
          this.loading.cme = true
          const params = { ids: ids.map(id => id * 1), entity: this.crudEntity, form: savedData, diffs: Object.keys(this.initForm.diffs) }

          this.$axios
            .post(`${this.apiPrefix}entities/edit/`, params)
            .then(() => {
              this.crudCancelActionConfirmed()
            })
            .catch(this.$utils.catchError)
            .then(() => {
              this.loading.cme = false
            })
        })
        .catch((err) => {
          this.loading.cme = false
          this.$utils.notifyNotAllValid(err)
        })
    },
    crudSaveActionConfirmed(next = false) {
      this.$refs?.editForm
        ?.validate?.()
        .then(() => {
          this.loading.crudSave = true
          const savedData = this.renderSavedItem(this.$utils.cloneDeep(this.form))
          delete savedData._Relations
          const apiParams = { item: { ...savedData } }
          this.crudApiFormKeys.forEach((element) => {
            const [apiKey, formKey] = Object.entries(element)[0]
            if (apiParams.item?.[formKey] !== undefined) {
              apiParams[apiKey] = apiParams.item[formKey]
              delete apiParams.item[formKey]
            }
          })
          apiParams.zeroAmountsWarnings = this.$store.getters['auth/userLocalSettings']?.showZeroAmountsWarnings || false
          if (this.duplicatedMode) {
            apiParams.duplicated = true
            apiParams.duplicatedFields = []
            Object.entries(this.docs).forEach(([apiFieldName, docData]) => {
              if (apiFieldName !== 'form' && docData?.duplicate === true) {
                apiParams.duplicatedFields.push(apiFieldName)
              }
            })
            if (!apiParams.duplicatedFields.length) {
              delete apiParams.duplicatedFields
            }
          }
          if (this.syncErrorsDetails.syncForced) {
            if (this.syncErrorsDetails.syncRefers) {
              apiParams.force_references = true
            } else {
              apiParams.skip_references = true
            }
          } else if (this.apiValidationWarningsDetails.skip) {
            apiParams.skip_warnings = true
          }
          this.$axios
            .post(`${this.apiPrefix + this.api}/`, apiParams)
            .then((response) => {
              if (this.id) {
                this.onUpdateItem(response, savedData)
              } else {
                if ((response?.data?.duplicatedFields || apiParams.duplicatedFields) && !this.disableDuplicationDiff) {
                  const diffs = []
                  const responseDuplicatedFields = (response?.data?.duplicatedFields || []).reduce(
                    (a, v) => ({ ...a, [v]: v }),
                    {}
                  )
                  const apiParamsDuplicatedFields = (apiParams.duplicatedFields || []).reduce((a, v) => ({ ...a, [v]: v }), {});
                  [...(response?.data?.duplicatedFields || []), ...(apiParams.duplicatedFields || [])].forEach((apiFieldName) => {
                    if (!responseDuplicatedFields?.[apiFieldName] || !apiParamsDuplicatedFields?.[apiFieldName]) {
                      diffs.push(apiFieldName)
                    }
                  })
                  if (diffs.length) {
                    this.$notify({
                      title: 'Error',
                      type: 'warning',
                      customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
                      duration: 9000,
                      message: `Inconsistent list of duplicated fields (diffs: ${diffs.join(
                        ', '
                      )}). Please report to the developer.`
                    })
                  }
                }
                this.onAddNewItem(response, savedData, next, !!apiParams.duplicatedFields || this.duplicatedMode)
              }
              this.currentActionDescription = ''
              this.duplicatedMode = false
              this.newVersionMode = false
            })
            .catch((error) => {
              if (error?.response?.data?.errors?.sync_error && error?.response?.data?.details?.sync_error?.length) {
                this.syncErrorsDetails = {
                  sync_error: error.response.data.details.sync_error
                }
                this.syncErrorsDialog = true
              } else if (error?.response?.data?.status === 'warning' && error?.response?.data?.warnings?.length) {
                this.apiValidationWarningsDetails = { warnings: error.response.data.warnings }
                this.apiValidationWarningsDialog = true
              } else {
                this.$utils.catchFormErrors(this.externalErrors)(error)
              }
              this.loading.crudSave = false
            })
            .then(() => {
              if (this.syncErrorsDetails.syncForced) {
                this.syncErrorsDetails = {
                  sync_error: [],
                  syncForced: false,
                  syncRefers: false
                }
                this.syncErrorsDialog = false
              } else if (this.apiValidationWarningsDetails.skip) {
                this.apiValidationWarningsDetails = {
                  warnings: [],
                  skip: false
                }
                this.apiValidationWarningsDialog = false
              }
            })
        })
        .catch((err) => {
          this.loading.crudSave = false
          this.$utils.notifyNotAllValid(err)
        })
    },
    crudSaveManyAction() {
      this.crudSaveAction(true)
    },
    onUpdateItem(response, savedData) {
      this.$message({
        message: 'saved',
        type: 'success',
        offset: 40
      })
      this.updateTitle(response?.data?.admin?.label, savedData)
      this.metaData.imgUrl = response?.data?.admin?.icon || ''
      this.loading.crudSave = false
      if (this.isModalMode) {
        this.$emit('change')
        return false
      }
      if (this.reLoadDataAfterSave) {
        this.loadData()
      } else {
        this.initForm.form = merge({}, this.form)
        this.initForm.diffs = {}
      }
    },
    onAddNewItem(response, savedData, next = false, forceReLoad = false) {
      this.$message({
        message: 'new item added',
        type: 'success',
        offset: 40
      })
      if (next) {
        this.form = {}
        this.showEmptyForm()
        this.loading.crudSave = false
      } else {
        this.id = this.form.ID = response?.data?.id || 0
        if (this.form?.Versioned && !this.newVersionMode) {
          this.form.Versioned.Root = this.id
        }
        if (!this.isModalMode) {
          this.$router.replace({
            name: `${this.$route.name}`,
            params: { id: this.id },
            query: { duplicate: undefined, new_version_of: undefined }
          })
        }
        this.updateTitle(response?.data?.admin?.label, { ...savedData, ID: this.id })
        this.metaData.imgUrl = response?.data?.admin?.icon || ''
        this.metaData.action = 'edit'
        this.loading.crudSave = false
        if (this.isModalMode) {
          this.$emit('change', this.id)
          return false
        }
        if (this.reLoadDataAfterSave || forceReLoad) {
          this.loadData()
        } else {
          this.initForm.form = merge({}, this.form)
          this.initForm.diffs = {}
        }
      }
    },
    crudDuplicateAction() {
      this.loading.crudDuplicate = true
      this.currentActionDescription = 'save duplicated data'
      let confirmMsg =
        'The form is ready to save a duplicate item (please change/complete the unique data before saving the data)'
      if (this.versioned) {
        if (this.form.Versioned.Root !== this.form.ID) {
          confirmMsg =
            'The form is ready to save a duplicate child item as new root item (please change/complete the unique data before saving the data)'
        }
        this.form.Versioned.IsDev = true
        this.form.Versioned.IsProd = this.forceAllVersioned
        this.form.Versioned.Root = 0
        this.versionedData = {
          admin_root: {},
          root: {},
          children: []
        }
      }
      if (!this.isModalMode) {
        this.$router.replace({ name: `${this.$route.name}`, params: { id: 0 }, query: { duplicate: this.form.ID } })
      }
      this.id = 0
      this.duplicatedMode = true
      delete this.form.ID
      this.metaData.action = 'new'
      this.form = this.renderDuplicateItem(this.form)
      this.$message({
        message: confirmMsg,
        type: 'success',
        offset: 40,
        duration: 4000
      })
      this.loading.crudDuplicate = false
      this.updateTitle(`add new duplicate item ${this.versioned ? '(as root)' : ''}`, null, false)
    },
    crudNewVersionAction() {
      this.loading.crudNewVersion = true
      this.currentActionDescription = 'save new version data'
      if (this.versioned) {
        this.form.Versioned.IsDev = true
        this.form.Versioned.IsProd = this.forceAllVersioned
      }
      if (!this.isModalMode) {
        this.$router.replace({
          name: `${this.$route.name}`,
          params: { id: 0 },
          query: { new_version_of: this.form.ID }
        })
      }
      this.newVersionMode = true
      this.id = 0
      delete this.form.ID
      this.metaData.action = 'new'
      this.form = this.renderVersionedItem(this.form)
      this.$message({
        message:
          'The form is ready to save a new version of current root item (please change/complete the unique data before saving the data)',
        type: 'success',
        offset: 40,
        duration: 4000
      })
      this.loading.crudNewVersion = false
      this.updateTitle('add a new version of current root item ', null, false)
    },
    crudUsageAction() {
      this.usageDialog = true
    },
    crudLogsAction() {
      this.logsDialog = true
    },
    crudDeleteAction() {
      this.$messageBox
        .confirm('Are you sure to delete this ?', undefined, {
          confirmButtonText: 'Yes, delete',
          type: 'warning'
        })
        .then(() => {
          this.loading.crudDelete = true
          this.$axios
            .delete(`${this.apiPrefix + this.api}/`, {
              params: {
                id: this.form.ID
              }
            })
            .then(() => {
              this.$message({
                message: 'deleted',
                type: 'success',
                offset: 40
              })
              if (this.isModalMode) {
                this.$emit('change')
                return false
              } else {
                let storageCrudSelectIds = null
                try {
                  storageCrudSelectIds = JSON.parse(localStorage.getItem('crudSelectIds'))
                } catch (_e) {
                  storageCrudSelectIds = null
                }
                if (
                  storageCrudSelectIds &&
                  storageCrudSelectIds?.routeName &&
                  storageCrudSelectIds?.routeName === this.$route?.meta?.parentName &&
                  storageCrudSelectIds.crudSelectIds?.[this.form.ID]
                ) {
                  delete storageCrudSelectIds.crudSelectIds[this.form.ID]
                  localStorage.setItem('crudSelectIds', JSON.stringify(storageCrudSelectIds))
                }
                this.crudCancelAction()
              }
            })
            .catch(this.$utils.catchError)
            .then(() => {
              this.loading.crudDelete = false
            })
        })
    },
    previewVersioned(id = 0) {
      return this.$utils.bindStaticParams({ name: `${this.$route.name}`, params: { id } })
    },
    refreshData() {
      this.loadData()
      if (this.isModalMode) {
        this.$emit('change')
      }
    },
    initCheckEditUsers() {
      const timeOffset = 90000 // 1.5 minute
      this.checkEditUserInterval = setInterval(() => {
        const periodDiff = new Date().getTime() - this.lastActivity
        if (periodDiff <= timeOffset) {
          this.checkEditUsers()
        } else {
          this.editingUsers = []
          this.lastActivity = null // null means user is not active
        }
      }, timeOffset) //
      this.editingUsersKey = this.$route.path
    },
    checkEditUsers() {
      this.$axios
        .post(`${this.proxyApHost + this.apiPrefix}open-form/open/`, {
          key: this.editingUsersKey
        })
        .then((result) => {
          this.editingUsers = result?.data?.users || []
        })
        .catch(this.$utils.catchError)
    },
    markLastActivity() {
      if (this.lastActivity === null) {
        // checkEditUsers immediately if the user resumes activity; regardless of the interval
        this.checkEditUsers()
      }
      this.lastActivity = new Date().getTime()
    },
    markAsChanged(fieldName, isChanged = true) {
      if (isChanged) {
        this.initForm.diffs[fieldName] = true
      } else {
        delete this.initForm.diffs[fieldName]
      }
    },
    triggerMarkAsChanged(fieldName) {
      if (this.initForm?.diffs) {
        const fieldPath = fieldName.split('.')
        if (this.$utils.getByPath(this.initForm.form, fieldPath) !== this.$utils.getByPath(this.form, fieldPath)) {
          this.initForm.diffs[fieldName] = true
        } else {
          delete this.initForm.diffs[fieldName]
        }
      }
    },
    openDrawerDocumentation(docField, title, fieldsDocs = true, settingsField = {}, disableDocSettings = false) {
      this.currentDocData = {
        data: { ...this.docs?.[docField] },
        title,
        fieldsDocs,
        docField,
        settingsField,
        disableDocSettings
      }
      if (!fieldsDocs) {
        this.currentDocData.instanceNotes = this.instanceNotes
      }
      this.drawerDocumentation = true
    }
  }
}
</script>

<template>
  <Breadcrumb
    v-if="!isModalMode && isAccess"
    class="sm-mt-0 mb-2 mt-5"
    :back-routes="crudBackRoutes"
    :active-title="crudMultiChangesMode ? ($route?.query?.cdwe?.lengt ? 'duplicate' : 'multi edit') : metaData.action"
    :to-right="$windowWidth >= 640"
  />
  <!-- content -->
  <div
    class="mx-auto"
    style="max-width: 1700px"
  >
    <!-- slot form (with build in versioned) || slot after form -->
    <div
      class="relative mb-16 px-2"
      :class="{ 'mb-4 mt-20 sm:mt-16': isModalMode, 'mb-16': !isModalMode }"
    >
      <!-- slot top || versioned root -->
      <el-row
        v-if="
          !crudMultiChangesMode && (
            (versioned
              && initiated
              && (!(!form?.Versioned?.Root || (versionedData?.root?.ID && versionedData?.root?.ID === form?.ID))
                || versionedData?.children?.length))
            || $slots?.top
          )
        "
        :gutter="20"
        class="gs-font-scaled px-5"
      >
        <el-col
          v-if="form?.Versioned?.Root && versionedData?.root?.ID && versionedData?.root?.ID !== form?.ID"
          class="mb-1.5"
          :span="12"
        >
          <router-link
            :to="previewVersioned(versionedData?.root?.ID)"
            target="_blank"
          >
            <el-button
              class="gs-btn-outlined-primary-light gs-height-related-sm font-related-lg px-1"
              :icon="icons.Edit"
            >
              child of Root: #{{ versionedData?.root?.ID }}
            </el-button>
          </router-link>
        </el-col>
        <el-col
          v-else-if="versionedData?.children?.length"
          class="mb-1.5"
        >
          <span class="font-related-lg font-medium text-neutral-400">Root with children:</span>
          <router-link
            v-for="child in versionedData.children"
            :key="child.ID"
            :to="previewVersioned(child.ID)"
            target="_blank"
          >
            <el-button
              class="gs-btn-outlined-primary-light gs-height-related-sm font-related-lg mx-1.5 px-1"
              :icon="icons.Edit"
            >
              #{{ child.ID }}
            </el-button>
          </router-link>
        </el-col>
        <el-col :span="versioned ? 12 : 24">
          <slot name="top" />
        </el-col>
      </el-row>
      <div
        v-if="!initiated && isAccess === undefined"
        class="absolute left-1/2 top-16 -translate-x-1/2 text-center text-cyan-400"
        style="animation: opacityOn 1s normal forwards step-end"
      >
        <div>
          <el-icon
            class="is-loading"
            :size="35"
          >
            <Loading />
          </el-icon>
        </div>
        <div class="pl-2.5 text-sm">
          loading form ...
        </div>
      </div>
      <div
        v-if="isAccess === false"
        class="absolute left-1/2 top-16 -translate-x-1/2 text-center text-neutral-500"
        style="animation: opacityOn 1s normal forwards step-end"
      >
        <div class="pl-2.5 text-sm">
          no permission
        </div>
      </div>
      <el-form
        v-if="initiated && isAccess"
        ref="editForm"
        :model="form"
        label-position="top"
        class="px-1 sm:px-4"
        :class="{ 'max-w-[100vw] sm:max-w-full sm:min-w-[1200px]': mobileSingleColumn, 'min-w-[1200px]': !mobileSingleColumn }"
      >
        <!-- row build in versioned -->
        <el-row
          v-if="versioned && !crudMultiChangesMode"
          :gutter="40"
          class="px-5"
          :class="{ 'pt-1': isModalMode }"
        >
          <!-- versioned env -->
          <CrudDetailsField
            :span="-1"
            label=""
            api-field-name="Versioned.Env"
            :external-errors="externalErrors"
            col-class="pr-0"
            class="order-1"
            :rules="[{ validator: (rule, value) => form.Versioned.IsDev || form.Versioned.IsProd, message: 'required' }]"
            doc-icon-class="-top-7 -right-1"
          >
            <template #default>
              <div class="my-1 flex flex-wrap gap-y-1.5">
                <el-checkbox
                  v-model="form.Versioned.IsDev"
                  class="gs-height-related-lg mr-2.5 px-2"
                  :class="[envSettings?.dev?.outlinedClasses]"
                  border
                  :disabled="!isVersionedAccess"
                >
                  <span class="">dev</span>
                </el-checkbox>
                <el-checkbox
                  v-model="form.Versioned.IsProd"
                  class="gs-height-related-lg px-2"
                  :class="[envSettings?.prod?.outlinedClasses]"
                  border
                  :disabled="!forceAllVersioned"
                >
                  prod
                </el-checkbox>
              </div>
            </template>
          </CrudDetailsField>
          <el-col class="order-3 sm:hidden" />
          <!-- ab test -->
          <CrudDetailsField
            :xs="mobileSingleColumn ? 23 : 7"
            :sm="5"
            :label="false"
            api-field-name="Versioned.HipoAB"
            :external-errors="externalErrors"
            col-class="pr-0"
            class="order-3 sm:order-2"
            doc-icon-class="-top-7 -right-1"
          >
            <template #default="{ apiFieldName }">
              <AbSelector
                v-model="form.Versioned.HipoAB"
                v-bind="{ apiFieldName }"
                model-is-entity
                label=""
                full-width
                :disabled="!isVersionedAccess"
              />
            </template>
            <template #fieldPrepend>
              <div class="whitespace-nowrap pr-1">
                AB test:
              </div>
            </template>
          </CrudDetailsField>
          <!-- ab test group -->
          <crud-field-number
            :span="-1"
            :label="false"
            api-field-name="Versioned.HipoSegmentNo"
            class-component="w-16"
            class="order-2 sm:order-3"
            :form="form"
            :min="0"
            :max="4"
            :external-errors="externalErrors"
            :disabled="!isVersionedAccess"
            doc-icon-class="-top-7 -right-1"
          >
            <template #fieldPrepend>
              <div class="whitespace-nowrap pr-1">
                AB group:
              </div>
            </template>
          </crud-field-number>
        </el-row>
        <!-- slot form -->
        <el-row
          class="px-5"
          :gutter="40"
          :class="isModalMode ? formClassModalMode : [formClass, { 'mt-4 sm:mt-16': !disableTopMargin }]"
        >
          <slot
            :id="id"
            name="form"
            :form="form"
            :external-errors="externalErrors"
            :meta-data="metaData"
            :is-modal-mode="isModalMode"
            :refresh-data="refreshData"
            :form-refer="formRefer"
            :mark-as-changed="markAsChanged"
            :trigger-mark-as-changed="triggerMarkAsChanged"
            :crud-multi-changes-mode="crudMultiChangesMode"
          />
        </el-row>
      </el-form>
      <slot name="afterForm" />
    </div>
  </div>
  <!-- fixedActions -->
  <div
    v-if="initiated && fixedActions && isAccess"
    class="gs-font-scaled right-0 z-10 border-b-4 border-slate-50 bg-neutral-50 bg-opacity-75 backdrop-blur-sm"
    :class="{ 'fixed shadow-inner': !isModalMode, 'absolute left-0': isModalMode }"
    :style="{ left: $store.getters['main/pageMenuWidth'], top: isModalMode ? '0px' : 'var(--gs-bar-height)' }"
  >
    <el-scrollbar class="relative">
      <div
        class="relative mx-auto flex items-center pb-3 pl-2 pr-4 pt-4"
        style="max-width: 2200px"
      >
        <template v-if="!crudMultiChangesMode">
          <div
            v-if="editingUsers.length"
            class="leading-none"
          >
            <el-popover
              trigger="hover"
              placement="right-end"
              :show-after="400"
              :width="300"
            >
              <template #reference>
                <el-icon class="gs-scaled-icon-lg cursor-help text-orange-400">
                  <InfoFilled class="animate-pulse" />
                </el-icon>
              </template>
              <template #default>
                <div class="gs-font-scaled">
                  <div class="font-related-sm mb-1 font-semibold">
                    Editing users:
                  </div>
                  <div
                    v-for="(email, index) in editingUsers"
                    :key="index"
                    class="font-related-sm leading-snug text-stone-500"
                  >
                    {{ email }}
                  </div>
                </div>
              </template>
            </el-popover>
          </div>
          <slot name="actionsFixedLeft" />
          <el-tooltip
            v-if="(docMode || docs?.form?.description?.length || instanceNotes?.length) && !disableDoc"
            effect="light"
            placement="top"
            content="form documentation"
            :show-after="600"
          >
            <el-button
              size="small"
              class="gs-btn-outlined-primary-light gs-font-scaled gs-height-related-xl px-1"
              @click="openDrawerDocumentation('form', 'The entire form', false)"
            >
              <icon-ify
                icon="bxs:help-circle"
                class="h-6 w-6"
              />
            </el-button>
          </el-tooltip>
          <div class="flex-1">
            <slot
              name="actionsFixeMiddle"
              :form="form"
              :is-modal-mode="isModalMode"
              :refresh-data="refreshData"
              :form-refer="formRefer"
            />
          </div>
          <!-- apply items tags button -->
          <div
            v-if="enableRelations"
            class="shrink-0"
          >
            <el-tooltip
              effect="light"
              placement="top"
              :show-after="600"
              content="Apply items tags"
            >
              <el-button
                class="gs-loading gs-font-scaled gs-height-related-xl gs-btn-outlined-warning mr-2 px-3"
                :loading="loading.applyItemsTags"
                @click="applyItemsTagsDialog = true"
              >
                Apply
              </el-button>
            </el-tooltip>
          </div>
        </template>
        <div
          v-else
          class="flex-1 pl-5 text-base text-orange-500 font-bold"
        >
          <div
            v-if="$route?.query?.cdwe?.length"
          >
            !!! Duplicate with edit !!!
          </div>
          <div v-else>
            !!! Multi edit !!!
          </div>
          <div class="text-xs text-neutral-500 font-normal">
            you can replace string values using square brackets and arrow e.g. MultiOfferGroup_event_[s36->s37]_14
          </div>
        </div>
        <!-- cancel/back actions -->
        <div>
          <el-button
            v-bind="cancelAction.elAttr"
            class="gs-loading gs-font-scaled gs-height-related-xl relative px-3"
            :loading="loading[cancelAction.name]"
            @click="triggerBuiltInActions(cancelAction.name)"
          >
            {{ cancelAction.label }}
            <div
              v-if="!!initFormDiffsCounter"
              class="absolute -right-1 -top-2.5 inline-block bg-white/80"
            >
              <icon-ify
                icon="material-symbols:edit-notifications-outline"
                class="gs-scaled-icon-xs text-orange-400"
              />
            </div>
          </el-button>
        </div>
        <template v-if="!crudMultiChangesMode">
          <slot
            name="actionsFixed"
            :form="form"
            :is-modal-mode="isModalMode"
            :refresh-data="refreshData"
            :form-refer="formRefer"
          />
          <!-- built-in actions -->
          <div class="shrink-0">
            <el-button
              v-for="(actionData, actionApiName, index) in actionsList"
              :key="index"
              class="gs-loading gs-font-scaled gs-height-related-xl relative px-3"
              v-bind="actionData?.elAttr"
              :loading="loading[actionApiName]"
              :disabled="disableActions?.[actionApiName]"
              @click="triggerBuiltInActions(actionApiName)"
            >
              {{ actionData?.label }}
              <div
                v-if="actionApiName === 'crudSave' && userValidationArr.length"
                class="absolute -right-1 -top-2.5 inline-block bg-white/80"
                @click.stop
              >
                <icon-ify
                  icon="mdi:alert"
                  class="gs-scaled-icon-xs cursor-text text-orange-400"
                  @click.stop
                />
              </div>
            </el-button>
          </div>
          <slot
            name="actionsFixedRight"
            :form="form"
            :is-modal-mode="isModalMode"
            :refresh-data="refreshData"
            :form-refer="formRefer"
          />
        </template>
        <template v-else>
          <div
            v-if="$route?.query?.cdwe?.length"
            class="shrink-0"
          >
            <el-button
              class="gs-loading gs-font-scaled gs-height-related-xl relative px-3 gs-btn-primary ml-2"
              :icon="icons.CopyDocument"
              :loading="loading.cdwe"
              @click="triggerBuiltInActions('crudCdwe')"
            >
              duplicate with edit
            </el-button>
          </div>
          <div
            v-else
            class="shrink-0"
          >
            <el-button
              class="gs-loading gs-font-scaled gs-height-related-xl relative px-3 gs-btn-primary ml-2"
              :icon="icons.Edit"
              :loading="loading.cme"
              @click="triggerBuiltInActions('crudCme')"
            >
              multi edit
            </el-button>
          </div>
        </template>
        <MoreActions v-if="!disableDoc" />
      </div>
      <div class="w-full px-2 pb-3 pt-2 sm:hidden">
        <icon-ify
          icon="mdi:gesture-swipe"
          class="gs-scaled-icon-xs absolute bottom-1 left-1/2 mr-0.5 text-neutral-400"
        />
      </div>
    </el-scrollbar>
  </div>
  <UsageDialog v-if="usageDialog" />
  <LogsDialog v-if="logsDialog" />
  <ApplyItemTagsDialog />
  <!-- drawers -->
  <slot
    :id="id"
    name="drawers"
    :refresh-data="refreshData"
    :form="form"
  />
  <SyncErrorsDialog
    v-if="syncErrorsDialog"
    details-page
  />
  <ApiValidationWarningsDialog
    v-if="apiValidationWarningsDialog"
    details-page
  />
  <CrudDetailsDocumentationDrawer v-if="!disableDoc" />
</template>

<style>
@keyframes opacityOn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
</style>
