<script>
import { Bottom, CircleClose, DCaret, Plus, Search, Top } from '@element-plus/icons-vue'
import { debounce } from 'lodash'
import { defineAsyncComponent, inject } from 'vue'
import { mapGetters } from 'vuex'
import FieldsLabel from '!/components/shared/FieldsLabel.vue'
import CrudTypeFields from '!/components/crud/sub/CrudTypeFields.vue'
import { RenderApiColumnType } from '!/components/crud/mixins/crud-table/Columns'
import { AddNewByGlobalSearchProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'

export default {
  name: 'DynamicSelector',
  components: {
    FieldsLabel,
    CrudTypeFields,
    CircleClose,
    CellContent: defineAsyncComponent(() => import('!/components/crud/sub/CellContent.vue'))
  },
  mixins: [RenderApiColumnType, AddNewByGlobalSearchProps],
  inheritAttrs: false,
  props: {
    modelValue: {
      type: undefined,
      default: undefined
    },
    modelIsEntity: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: undefined,
      validator: (propValue) => {
        return ['', 'small', 'large'].includes(propValue)
      }
    },
    label: {
      type: [String, Boolean],
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    columnsSettings: {
      type: Object,
      default: () => ({
        // 'colNameApi': {
        //   header: 'txt header', (optional - default colApiName)
        //   htmlValue: true, (use v-html on div (also requires a elAttr.formatter for security reasons))
        //   filterField: [false | true (name === colNameApi) | name_field (look at more info below)], (optional - default: true)
        //   elAttr: {attr for el-table-column (https://element-plus.org/en-US/component/table.html#table-column-attributes)}, (optional)
        //   width: shortcut for elAttr.width settings (optional)
        // } | false - hide action column
        //
        // more info:
        // name of filterField defines data filtering/searching method; eg name === colNameApi finds data equal to the searched value; name colNameApi_like finds data containing the searched value
        //
        // ID column have default settings (built-in settings)
      })
    },
    renderSelectionLabel: {
      type: Function,
      default(rowData) {
        return rowData?.AdminLabel || rowData?.ID || ''
      }
    },
    indexColumn: {
      type: String,
      default: 'ID'
    },
    api: {
      // api used for list
      type: String,
      default: ''
    },
    apiPrefix: {
      type: String,
      default: '/admin/api/'
    },
    width: {
      type: [String, Number],
      default: undefined
    },
    widthFieldPx: {
      type: [String, Number],
      default: undefined
    },
    showImgPreviewColumn: {
      type: Boolean,
      default: false
    },
    editModelRouteName: {
      type: [String, Function],
      default: ''
    },
    disableEditModel: {
      type: Boolean,
      default: false
    },
    initFilters: {
      type: Object,
      default: () => ({
        // 'colNameApi': initValue
      })
    },
    relationsRouteName: {
      type: [String, Function],
      default: ''
    },
    focusOnInit: {
      type: Boolean,
      default: false
    },
    selectorType: {
      type: String,
      default: 'dynamic_selector'
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, context) {
    const crudForm = context.attrs.form || inject('crudForm', {})
    const crudEnabledRelations = inject('enableRelations', false)
    const initForm = props.form ? {} : inject('initForm', {})
    const settingsCrudDetailsField = inject('settingsCrudDetailsField', {})
    const crudFormRefer = inject('crudFormRefer', {})
    settingsCrudDetailsField.type = props.selectorType || 'dynamic_selector'
    const crudRelations = inject('crudRelations', null)
    if (props.editModelRouteName && typeof props.editModelRouteName === 'string') {
      const route = app.$utils.getFullRouteData(props.editModelRouteName)
      const selector = route?.meta?.selector || null
      if (!selector) {
        app.$notify({
          title: 'Error',
          type: 'warning',
          customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
          message: `Selector with edit-model-route-name prop = "${props.editModelRouteName}" is incorrect (this route don't exist or there is no selector defined). Please report to developer.`,
          showClose: true,
          duration: 0
        })
      }
    }
    return {
      crudForm,
      initForm,
      crudEnabledRelations,
      crudRelations,
      crudFormRefer
    }
  },
  data() {
    return {
      fullInit: false,
      selections: [
        // {value: null, label: '', row: {}, index: null} // structure
      ],
      filterPopupVisible: false,
      filterPopupInitiated: false,
      filterPagination: {
        currentPage: 1,
        onPage: 100,
        total: 0
      },
      sorting: {
        order: '',
        prop: ''
      },
      searchPopupVisible: false,
      searchPopupInitiated: false,
      searchPopupCanceled: false,
      searchPagination: {
        currentPage: 1,
        onPage: 100,
        total: 0
      },
      typePopup: 'filter',
      tableRows: [],
      tableFilters: {},
      currentSelectedRowIndex: null,
      loading: {
        filterList: true,
        searchList: true
      },
      loaded: false,
      areFilters: false,
      isSorting: false,
      filterOptions: {},
      editDialogRoute: null,
      editDialogVisible: false,
      editDialogMetaData: {},
      selectorModelValue: '',
      apiColumns: {},
      relationsOptions: {},
      isFocused: false,
      globalSearch: '',
      customKey: '',
      isOverwrittenValue: false,
      debug: false
    }
  },
  computed: {
    bindAttr() {
      const bind = { ...this.$attrs }
      delete bind.form
      return bind
    },
    sizeClass() {
      return this.size ? `el-input--${this.size}` : `el-input--${this.userScaledSize}`
    },
    apiColumnsLength() {
      return Object.keys(this.apiColumns).length
    },
    tableCols() {
      return Object.fromEntries(
        Object.entries({ ID: { filterField: 'ID', elAttr: { width: 90, sortable: true } }, ...this.columnsSettings }).map(
          ([colApiName, colSettings]) => {
            const typeApiColumn = this.apiColumns?.[colApiName]
              ? this.renderApiColumnType(this.apiColumns?.[colApiName], colApiName)
              : {}
            const settings = {
              ...colSettings,
              elAttr: {
                ...{ label: colSettings?.header || this.$utils.convertApiNamesToHuman(colApiName), prop: colApiName },
                ...(colSettings?.elAttr || {})
              },
              typeValueField: typeApiColumn.type,
              typeApiColumn
            }

            if (settings.elAttr?.sortable !== undefined) {
              settings._sortable = settings.elAttr?.sortable !== false
              settings.elAttr.sortable = false
            } else {
              settings._sortable = typeApiColumn.sort
              settings.elAttr.sortable = false
            }

            if (settings?.filterField === undefined || settings?.filterField === true) {
              settings.filterField = colApiName
              if (typeApiColumn.like) {
                settings.filterField = `${colApiName}_like`
              }
              if (typeApiColumn.filterSuffix) {
                settings.filterField = `${colApiName}_${typeApiColumn.filterSuffix}`
              }
            }

            if (settings.width !== undefined) {
              settings.elAttr.width = settings.width
            }
            if (settings.elAttr.width !== undefined) {
              settings.elAttr.width = settings.elAttr.width + this.$store.getters['auth/userScaledWidth']
            }
            if (!settings.elAttr.width && settings.elAttr['min-width'] === undefined) {
              settings.elAttr['min-width'] = '250px'
            }
            return [colApiName, settings]
          }
        )
      )
    },
    tableRowsArr() {
      return Object.entries(this.tableRows) || []
    },
    widthPopover() {
      if (this.width !== undefined) {
        return Math.ceil(
          Math.min(this.$windowWidth * 0.95, 1100, Math.max(this.width * this.userScaledRatioWidth, this.$windowWidth * 0.5))
        )
      }
      return Math.ceil(
        Math.min(this.$windowWidth * 0.95, 1100, Math.max(660 * this.userScaledRatioWidth, this.$windowWidth * 0.5))
      )
    },
    initFiltersArr() {
      return Object.entries(this.initFilters)
    },
    visibleColumnsNames() {
      return Object.entries(this.tableCols)
        .map(([colName]) => {
          return colName
        })
        .join(',')
    },
    ...mapGetters({
      userScaledWidth: 'auth/userScaledWidth',
      userScaledSize: 'auth/userScaledSize',
      userScaledRatio: 'auth/userScaledRatio',
      userScaledRatioWidth: 'auth/userScaledRatioWidth'
    }),
    enabledRelations() {
      return !!this.relationsRouteName?.length && (!!this.crudRelations || this.crudEnabledRelations)
    },
    relationsFieldName() {
      if (this.apiFieldName?.substring(0, 5) === 'rows.' && this.crudForm?.rows.length) {
        const splitApiFieldName = this.apiFieldName.split('.')
        const rowID = this.crudForm.rows?.[splitApiFieldName[1]]?.ID
        if (rowID) {
          splitApiFieldName[1] = rowID
        } else {
          return ''
        }
        return splitApiFieldName.join('.')
      }
      return this.apiFieldName
    },
    relationValue() {
      return this.crudRelations?.[this.relationsFieldName]
    },
    apiFieldName() {
      return this.$attrs.apiFieldName || this.$attrs['api-field-name']
    }
  },
  watch: {
    selections: {
      handler() {
        this.selectorModelValue = this.selections?.[0]?.label || ''
        const emitVal = this.modelIsEntity ? this.selections?.[0]?.row || null : this.selections?.[0]?.value
        if (!(emitVal === null || emitVal === undefined) && emitVal !== this.modelValue) {
          this.$emit('update:modelValue', emitVal)
          this.$emit('change', emitVal)
        }
        this.crudFormRefer?.clearValidate?.(this.apiFieldName)
        this.crudFormRefer?.validateField?.(this.apiFieldName)
      }
    }
  },
  created() {
    this.icons = {
      Bottom,
      Top,
      DCaret,
      Search,
      Plus
    }
    if (this.$route.query.debug) {
      this.debug = true
    }
  },
  mounted() {
    this.init()
  },
  unmounted() {
    document.removeEventListener('click', this.closePopupOnOutsideClick, false)
  },
  methods: {
    closePopupOnOutsideClick() {
      // this solutions don't close selector popup when are used  el-selects inside popup (eg as columns filters);
      this.filterPopupVisible = false
      this.searchPopupVisible = false
    },
    init() {
      this.initRelations()
      if (this.modelValue !== undefined && this.modelValue !== null && !this.selections.length && this.modelValue !== '') {
        if (
          this.modelIsEntity &&
          this.modelValue?.[this.indexColumn] &&
          this.modelValue.ID !== undefined &&
          this.modelValue.AdminIcon !== undefined &&
          this.modelValue.AdminLabel !== undefined
        ) {
          this.initModelValue({ items: [this.modelValue] })
        } else {
          this.fetchData(this.modelValue, true)
        }
      }
      this.$watch('modelValue', () => {
        if (!this.modelIsEntity && this.selections.length && `${this.modelValue}` !== `${this.selections[0].value}`) {
          this.selections = []
        } else if (
          this.modelIsEntity &&
          this.selections.length &&
          `${this.modelValue?.[this.indexColumn]}` !== `${this.selections[0].value}`
        ) {
          this.selections = []
        }
        if (
          !this.selections.length &&
          this.modelValue !== undefined &&
          this.modelValue !== null &&
          ((!this.modelIsEntity && `${this.modelValue}` !== '') || (this.modelIsEntity && this.modelValue?.[this.indexColumn]))
        ) {
          if (
            this.modelIsEntity &&
            this.modelValue.ID !== undefined &&
            this.modelValue.AdminIcon !== undefined &&
            this.modelValue.AdminLabel !== undefined
          ) {
            this.initModelValue({ items: [this.modelValue] })
          } else {
            this.fetchData(this.modelValue, true)
          }
        } else if (this.modelIsEntity && this.modelValue?.[this.indexColumn]) {
          this.selectorModelValue = this.selections.label = this.renderSelectionLabel(this.modelValue)
          this.selections.row = this.modelValue
        }
        if (this.initForm?.diffs && this.apiFieldName) {
          const fieldNamePath = this.apiFieldName.split('.')
          const checkModelValue = this.modelIsEntity ? this.modelValue?.[this.indexColumn] : this.modelValue
          const checkInitValue = this.modelIsEntity
            ? this.$utils.getByPath(this.initForm.form, fieldNamePath)?.[this.indexColumn]
            : this.$utils.getByPath(this.initForm.form, fieldNamePath)
          if (checkInitValue !== checkModelValue) {
            this.initForm.diffs[this.apiFieldName] = true
          } else {
            delete this.initForm.diffs[this.apiFieldName]
          }
        }
      })
      const unwatchFilterPopupVisible = this.$watch('filterPopupVisible', (val) => {
        if (val) {
          unwatchFilterPopupVisible()
          this.loadData = debounce(this.loadData, 500)
          document.addEventListener('click', this.closePopupOnOutsideClick, false)
          this.filterPopupInitiated = true
          this.setInitFilters(false)
          this.$watch('filterPagination.currentPage', () => {
            this.loadData()
          })
          this.$watch('filterPagination.onPage', () => {
            this.loadData()
          })
          this.$watch(
            'tableFilters',
            () => {
              if (this.filterPopupVisible) {
                this.loadData()
                this.areFilters = Object.entries(this.tableFilters).some(([, value]) => {
                  return `${value}`.length && value !== null && value !== undefined
                })
              }
            },
            { deep: true }
          )
        }
      })
      const unwatchSearchPopupVisible = this.$watch('searchPopupVisible', (val) => {
        if (val) {
          unwatchSearchPopupVisible()
          this.loadSearchData = debounce(this.loadSearchData, 500)
          document.addEventListener('click', this.closePopupOnOutsideClick, false)
          this.searchPopupVisible = true
          this.$watch('searchPagination.currentPage', () => {
            this.loadSearchData()
          })
          this.$watch('searchPagination.onPage', () => {
            this.loadSearchData()
          })
        }
      })
      if (this.focusOnInit) {
        this.revertFocusField()
      }
    },
    initRelations() {
      if (this.enabledRelations) {
        this.relationsOptions.visibleDialog = false
        this.relationsOptions.route = {}
        const routeName =
          (typeof this.relationsRouteName !== 'function' ? this.relationsRouteName : this.relationsRouteName()) || ''
        if (routeName) {
          this.relationsOptions.route = this.$utils.getFullRouteData({ name: routeName })
        }
        this.relationsOptions.closeDialog = () => {
          this.relationsOptions.visibleDialog = false
        }
        this.relationsOptions.changeRelation = (relationValue, closeDialog = true) => {
          this.crudRelations[this.relationsFieldName] = relationValue || ''
          if (closeDialog) {
            this.relationsOptions.closeDialog()
          }
        }
      }
    },
    initModelValue(data, overwrite = false) {
      this.isOverwrittenValue = overwrite
      if (this.modelValue !== null || overwrite) {
        if (
          overwrite ||
          (!this.modelIsEntity &&
            data?.items?.[0]?.[this.indexColumn] &&
            `${this.modelValue}` === `${data.items[0][this.indexColumn]}`) ||
            (this.modelIsEntity &&
              data?.items?.[0]?.[this.indexColumn] &&
              `${this.modelValue?.[this.indexColumn]}` === `${data.items[0][this.indexColumn]}`)
        ) {
          const row = data.items[0]
          this.selections = [
            {
              value: row[this.indexColumn],
              label: this.renderSelectionLabel(row),
              row,
              index: undefined
            }
          ]
        } else {
          this.selections = [
            {
              value: this.modelValue?.[this.indexColumn] || this.modelValue,
              label: '!!!not exist!!!',
              index: undefined
            }
          ]
        }
      }
    },
    updateList(data) {
      this.loaded = true
      this.tableRows = data?.items || []
      if (this.filterPopupVisible) {
        this.filterPagination.total = data?.total || 0
        this.setSelectedRow(this.findRowByIndexValue(this.selections?.[0]?.value))
      } else {
        this.searchPagination.total = data?.total || 0
      }
    },
    fetchData(modelValue = null, forceUpdateModel = false) {
      if (!this.filterPopupVisible && !forceUpdateModel) {
        return false
      }
      this.loading.filterList = true
      this.setSelectedRow(null)
      let params = {}
      if (modelValue === null) {
        params = {
          page: this.filterPagination.currentPage > 1 ? this.filterPagination.currentPage : undefined,
          on_page: this.filterPagination.onPage,
          sort: this.sorting.prop ? this.sorting.prop : undefined,
          sort_desc: this.sorting.prop ? this.sorting.order === 'descending' : undefined,
          ...this.tableFilters,
          columns: this.visibleColumnsNames
        }
      } else {
        const label = this.renderSelectionLabel(modelValue)
        if (label && modelValue?.AdminLabel !== undefined && modelValue?.ID !== undefined && !forceUpdateModel) {
          this.initModelValue({ items: [modelValue] }, true)
          return false
        }
        params = { [this.indexColumn]: this.modelIsEntity ? modelValue?.[this.indexColumn] : modelValue }
      }
      const needFocus = !this.loaded
      this.$axios(`${this.apiPrefix + this.api}/`, { params })
        .then(({ data }) => {
          this.apiColumns = data?.columns ? { ID: 'uint:sort', ...data.columns } : {}
          if (modelValue === null) {
            this.updateList(data)
          } else {
            this.initModelValue(data, forceUpdateModel)
          }
          if (needFocus) {
            this.$utils.nextLoopEvent().then(() => {
              this.focusGlobalSearch()
            })
          }
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.filterList = false
        })
    },
    loadData(modelValue = null) {
      this.fetchData(modelValue)
    },
    loadSearchData() {
      if (!this.searchPopupVisible) {
        return false
      }
      this.loading.searchList = true
      this.currentSelectedRowIndex = null
      this.setSelectedRow(null)
      let params = {}
      params = {
        page: this.searchPagination.currentPage > 1 ? this.searchPagination.currentPage : undefined,
        on_page: this.searchPagination.onPage,
        columns: this.visibleColumnsNames,
        global_search: this.selectorModelValue,
        simple_search: 'on'
      }
      this.$axios(`${this.apiPrefix + this.api}/`, { params })
        .then(({ data }) => {
          this.apiColumns = data?.columns ? { ID: 'uint:sort', ...data.columns } : {}
          this.updateList(data)
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.searchList = false
        })
    },
    removeSelection(index) {
      this.setSelectedRow(null)
      this.selections.splice(index, 1)
      const emitVal = this.modelIsEntity ? null : undefined
      this.$emit('update:modelValue', emitVal)
      this.$emit('change', emitVal)
      this.crudFormRefer?.clearValidate?.(this.apiFieldName)
      this.crudFormRefer?.validateField?.(this.apiFieldName)
    },
    onSelectRow(row) {
      this.currentSelectedRowIndex = this.tableRows.indexOf(row)
    },
    getPopupRefer() {
      // DON'T use as computed !!!! because not works;
      return this.typePopup === 'filter' ? this.$refs.tableFilter : this.$refs.tableSearch
    },
    setSelectedRow(rowIndex) {
      this.getPopupRefer()?.setCurrentRow?.(this.tableRows?.[rowIndex])
    },
    onSort(colApiName) {
      const disableCurrentSorting = this.sorting.prop && this.sorting.prop === colApiName && this.sorting.order === 'descending'
      this.sorting = {
        prop: disableCurrentSorting ? '' : colApiName,
        order: disableCurrentSorting || this.sorting.prop !== colApiName ? 'anscending' : 'descending'
      }
      this.isSorting = !!this.sorting.prop
      this.loadData()
    },
    selectByOffset(offset) {
      const nextIndex = this.currentSelectedRowIndex + offset
      if (offset > 0) {
        if (this.currentSelectedRowIndex === null) {
          return this.setSelectedRow(0)
        }
        if (this.tableRows.length >= nextIndex + 1) {
          if (nextIndex > 5) {
            this.getPopupRefer().scrollTo({
              top: this.getPopupRefer().$el.querySelectorAll('.el-scrollbar__wrap')[0].scrollTop + 20,
              behavior: 'smooth'
            })
          }
          return this.setSelectedRow(nextIndex)
        }
      } else {
        if (nextIndex >= 0) {
          if (nextIndex < 7) {
            this.getPopupRefer().scrollTo({
              top: this.getPopupRefer().$el.querySelectorAll('.el-scrollbar__wrap')[0].scrollTop - 20,
              behavior: 'smooth'
            })
          }
          return this.setSelectedRow(nextIndex)
        }
      }
    },
    confirmSelect() {
      this.$nextTick(() => {
        if (this.currentSelectedRowIndex !== null) {
          if (this.tableRows?.[this.currentSelectedRowIndex]) {
            this.selections = [
              {
                value: this.tableRows[this.currentSelectedRowIndex][this.indexColumn],
                label: this.renderSelectionLabel(this.tableRows[this.currentSelectedRowIndex]),
                row: this.tableRows[this.currentSelectedRowIndex],
                index: this.currentSelectedRowIndex
              }
            ]
          }
          this.searchPopupVisible = false
          this.filterPopupVisible = false
        }
      })
    },
    onBeforeLeavePopover() {
      this.getPopupRefer()?.scrollTo?.({
        top: 0
      })
      if (this.tableRows.length && this.typePopup !== 'filter' && !this.searchPopupCanceled && this.selectorModelValue?.length) {
        if (!this.isOverwrittenValue && (!this.tableRows?.[this.currentSelectedRowIndex] || this.tableRows.length === 1)) {
          this.currentSelectedRowIndex = 0
        }
        if (
          this.tableRows[this.currentSelectedRowIndex][this.indexColumn] &&
          this.selections?.[0]?.value !== this.tableRows[this.currentSelectedRowIndex][this.indexColumn]
        ) {
          this.confirmSelect()
        }
      } else {
        this.setSelectedRow(null)
      }
      this.isOverwrittenValue = false
    },
    leaveFilterPopover() {
      this.reset()
      this.tableRows = []
      this.currentSelectedRowIndex = null
      this.loading.filterList = true
      this.customKey = ''
    },
    findRowByIndexValue(indexValue) {
      let rowIndex = null
      this.tableRowsArr.some(([index, row]) => {
        if (indexValue === row?.[this.indexColumn]) {
          rowIndex = index
          return true
        }
        return false
      })
      return rowIndex
    },
    reset(resetOnPage = false) {
      this.filterPagination = {
        currentPage: 1,
        onPage: this.filterPagination.onPage,
        total: 0
      }
      if (resetOnPage) {
        this.filterPagination.onPage = 100
      }
      this.sorting = {
        order: '',
        prop: ''
      }
      this.globalSearch = ''
      this.setInitFilters()
    },
    openEditDialog(e, selection) {
      this.fullInit = true
      const routeName =
        (typeof this.editModelRouteName !== 'function' ? this.editModelRouteName : this.editModelRouteName(selection.row)) || ''
      if (routeName === '') {
        return false
      }
      if (!routeName?.length || !selection?.row?.ID) {
        return false
      }
      this.editDialogRoute = this.$utils.getFullRouteData({ name: routeName, params: { id: selection.row.ID } })
      if (routeName && typeof this.editModelRouteName === 'function') {
        const selector = this.editDialogRoute?.meta?.selector || null
        if (!selector) {
          this.$notify({
            title: 'Error',
            type: 'warning',
            customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
            message: `Selector mapping with entity (${routeName}) is missing. Please report to developer.`
          })
        }
      }

      if (e.metaKey || e.ctrlKey) {
        return this.openEditPage(this.editDialogRoute)
      }
      this.editDialogVisible = true
    },
    openEditPage(route) {
      window.open(route.href, '_blank')
    },
    setMetaData(data) {
      this.editDialogMetaData = data
    },
    closeEditDialog() {
      this.editDialogVisible = false
    },
    refreshSelector(id = null) {
      if (
        id &&
        ((this.modelIsEntity && this.modelValue?.ID && id !== this.modelValue.ID) ||
          (!this.modelIsEntity && this.modelValue && id !== this.modelValue))
      ) {
        this.fetchData(this.modelIsEntity ? { [this.indexColumn]: id } : id, true)
      } else {
        this.fetchData(this.modelValue, true)
      }
      this.fetchData()
      this.editDialogVisible = false
      this.relationsOptions.visibleDialog = false
    },
    setInitFilters(loadNewData = true) {
      this.tableFilters = {}
      this.initFiltersArr.forEach(([colApiName, initValue]) => {
        if (initValue !== undefined) {
          this.tableFilters[colApiName] = initValue
          this.areFilters = true
        }
      })
      if (loadNewData) {
        this.loadData()
      }
    },
    onConfirmAddNewByGlobalSearch() {
      this.loading.global_search = true
      this.$axios
        .post(`${this.apiPrefix}client-strings/new-for-entity-field/`, {
          entity: this.$route?.meta?.Entity,
          field: this.field,
          id: this.id,
          contents: this.typePopup === 'filter' ? this.tableFilters?.global_search : this.selectorModelValue,
          string_id: this.customKey.length ? this.customKey : undefined
        })
        .then((response) => {
          this.initModelValue({ items: [response.data.entity] }, true)
          this.$message({
            message: 'added',
            type: 'success',
            offset: 40
          })
          this.filterPopupVisible = false
          this.searchPopupVisible = false
        })
        .catch((error) => {
          if (error?.response?.data?.ID) {
            const notify = this.$notify({
              title: 'Limit translations exceeded',
              type: 'warning',
              customClass: 'bg-teal-50 text-red-600 z-[999999]',
              duration: 0,
              dangerouslyUseHTMLString: true,
              message: `Translation for this field already exists  <br/> <a class="font-semibold cursor-pointer hover:text-cyan-700">show this translation <span class="text-xs">(ID: ${error.response.data.ID})</span> </a>`,
              onClick: (e) => {
                if (e.target.nodeName === 'A') {
                  e.stopPropagation()
                  this.tableFilters = { ID: error.response.data.ID }
                  notify.close()
                }
              }
            })
          } else {
            this.$utils.catchError(error)
          }
        })
        .then(() => {
          this.loading.global_search = false
        })
    },
    onChangeSelector() {
      if (
        (this.selectorModelValue !== this.selections?.[0]?.label || (this.modelValue && !this.selectorModelValue.length)) &&
        !this.searchPopupVisible
      ) {
        this.selectorModelValue = ''
        this.removeSelection(0)
      } else if (this.searchPopupVisible && this.selections.length && !this.selectorModelValue.length) {
        this.removeSelection(0)
      }
    },
    leaveSearchPopover() {
      this.searchPagination = {
        currentPage: 1,
        onPage: this.searchPagination.onPage,
        total: 0
      }
      this.tableRows = []
      this.currentSelectedRowIndex = null
      if (this.selectorModelValue !== this.selections?.[0]?.label && !this.isFocused) {
        this.selectorModelValue = ''
        this.removeSelection(0)
      }
      this.loading.searchList = true
      this.searchPopupCanceled = false
      this.customKey = ''
    },
    onFocusField() {
      this.fullInit = true
      this.isFocused = true
      if (!this.searchPopupVisible) {
        this.$utils.nextLoopEvent(50).then(() => {
          if (this.isFocused) {
            this.$refs?.fieldRef?.select?.()
          }
        })
      }
    },
    onInputField() {
      this.filterPopupVisible = false
      if (this.selectorModelValue !== this.selections?.[0]?.label) {
        if (this.searchPopupVisible) {
          this.searchPagination.currentPage = 1
          this.loadSearchData()
        } else if (this.selectorModelValue.length) {
          this.searchPopupVisible = true
        }
        if (this.selections?.[0]?.row) {
          this.selections[0].label = ''
          this.selections[0].row.AdminIcon = ''
          delete this.selections[0].row.Class
        }
      }
    },
    onBlurField() {
      this.isFocused = false
      if (!this.searchPopupVisible) {
        this.leaveSearchPopover()
      }
    },
    openFilterPopover() {
      this.searchPopupVisible = false
      this.fullInit = this.filterPopupVisible = true
    },
    triggerFieldKeyActions(key, method, arg) {
      if (this.searchPopupVisible) {
        if (['enter', 'tab'].includes(key)) {
          if (!this.tableRows?.[this.currentSelectedRowIndex] || this.tableRows.length === 1) {
            this.currentSelectedRowIndex = 0
          }
        }
        method(arg)
        if (['tab'].includes(key)) {
          this.searchPopupVisible = false
        }
      } else if (!this.selectorModelValue.length) {
        this.searchPopupVisible = true
        this.onInputField()
      }
    },
    clearSelector() {
      this.selectorModelValue = ''
      this.removeSelection(0)
      if (this.searchPopupVisible) {
        this.searchPagination.currentPage = 1
        this.loadSearchData()
        this.revertFocusField()
      }
    },
    revertFocusField() {
      this.isFocused = false
      this.$utils.nextLoopEvent(100).then(() => {
        this.$refs?.fieldRef?.focus?.()
        this.isFocused = true
      })
    },
    focusGlobalSearch() {
      this.isFocused = false
      const focusFilterFieldRef = this.$refs?.tableFilter?.$el?.querySelectorAll?.(`[name="global_search"]`)?.[0]
      if (focusFilterFieldRef) {
        focusFilterFieldRef.focus()
      } else {
        this.$refs?.tableFilter?.$el?.focus?.()
      }
    },
    onCancelSearchPopover() {
      this.searchPopupCanceled = true
      this.searchPopupVisible = false
    }
  }
}
</script>

<template>
  <FieldsLabel
    ref="buttonRef"
    :label="label"
    :full-width="widthFieldPx === undefined"
  >
    <el-input
      ref="fieldRef"
      v-model="selectorModelValue"
      v-bind="bindAttr"
      class="gs-dynamic-selector relative"
      :style="{ width: `${widthFieldPx * userScaledRatioWidth}px` }"
      :class="{ 'w-full': fullWidth }"
      :size="size"
      @change="onChangeSelector"
      @focus="onFocusField"
      @input="onInputField"
      @blur="onBlurField"
      @keydown.down.prevent="triggerFieldKeyActions('down', selectByOffset, 1)"
      @keydown.up.prevent="triggerFieldKeyActions('up', selectByOffset, -1)"
      @keydown.enter.prevent="triggerFieldKeyActions('enter', confirmSelect)"
      @keydown.tab="triggerFieldKeyActions('tab', confirmSelect)"
      @keydown.esc.prevent="searchPopupVisible = false"
    >
      <template #suffix>
        <!-- clear icon -->
        <el-icon
          v-if="!disabled && selectorModelValue?.length"
          class="clear invisible absolute cursor-pointer text-black"
          @click.stop="clearSelector"
        >
          <CircleClose class="rounded bg-white" />
        </el-icon>
        <!-- edit icon -->
        <el-icon
          v-if="!disabled && selections?.[0]?.label && editModelRouteName"
          class="gs-scaled-icon-xs translate-x-0.5 cursor-pointer hover:text-blue-500"
          @click.stop="openEditDialog($event, selections?.[0])"
        >
          <icon-ify
            icon="material-symbols:edit-document-outline"
            class="h-full w-full opacity-50 hover:opacity-100"
          />
        </el-icon>
        <!-- relation icon -->
        <div
          v-if="enabledRelations && !disabled && relationsFieldName"
          class="block flex h-full translate-x-1 items-center"
          @click.stop="fullInit = relationsOptions.visibleDialog = true"
        >
          <el-tooltip
            placement="top"
            effect="light"
            :show-after="600"
          >
            <el-icon
              class="gs-scaled-icon-xs scale-95 cursor-pointer hover:scale-100"
              :class="{
                'text-orange-400 hover:text-orange-500': !!relationValue,
                'opacity-50 hover:opacity-100': !relationValue
              }"
            >
              <icon-ify
                icon="ph:link-bold"
                class="h-full w-full"
              />
            </el-icon>
            <template #content>
              <div
                v-if="!!relationValue"
                class="px-2"
              >
                <div>
                  Selected item tag: <b>{{ relationValue }}</b>
                  <span class="font-related-xss text-neutral-500 block">({{ relationsFieldName }})</span>
                </div>
                <div class="font-related-xss py-1 text-neutral-500">
                  [ click to change ]
                </div>
              </div>
              <div
                v-else
                class="px-2"
              >
                Add item tag relation
                <span class="font-related-xss text-neutral-500 block">({{ relationsFieldName }})</span>
              </div>
            </template>
          </el-tooltip>
        </div>
        <!-- filter icon -->
        <el-icon
          class="gs-scaled-icon-xs translate-x-1 cursor-pointer hover:text-blue-500"
          @click.stop="openFilterPopover"
        >
          <icon-ify
            icon="mdi:filter-settings-outline"
            class="h-full w-full"
            :class="{
              'text-blue-500 opacity-100': filterPopupVisible,
              'opacity-50 hover:opacity-100': !relationValue
            }"
          />
        </el-icon>
      </template>
    </el-input>
  </FieldsLabel>
  <template v-if="fullInit">
    <!-- search popover -->
    <el-popover
      :virtual-ref="$refs.buttonRef"
      :visible="searchPopupVisible"
      virtual-triggering
      :width="widthPopover"
      placement="bottom"
      :disabled="disabled"
      popper-class="p-0"
      @before-enter="loadSearchData"
      @show="typePopup = 'search'"
      @before-leave="onBeforeLeavePopover"
      @after-leave="leaveSearchPopover"
    >
      <div
        v-if="searchPopupInitiated || searchPopupVisible"
        class="gs-selector-wrapper p-2"
        :class="[$store.getters['auth/userScaledClass']]"
        @click.stop.prevent="revertFocusField"
      >
        <el-table
          ref="tableSearch"
          :data="tableRows"
          style="width: 100%"
          :style="[{ fontSize: $store.getters['auth/userScaledFontSize'] }]"
          size="small"
          class="search-table"
          :max-height="Math.ceil(Math.max($windowHeight * ($windowWidth < 640 ? 0.6 : 0.4), 350))"
          header-cell-class-name="border-b-4 border-neutral-50"
          highlight-current-row
          tabindex="0"
          :empty-text="loading.searchList ? 'loading data ...' : 'No Data'"
          @keydown.down="selectByOffset(1)"
          @keydown.up="selectByOffset(-1)"
          @current-change="onSelectRow"
          @row-click="confirmSelect"
          @keydown.enter="confirmSelect"
        >
          <el-table-column width="50">
            <template #default="{ $index }">
              <div class="flex items-center justify-center">
                <el-icon
                  v-if="currentSelectedRowIndex === $index"
                  class="gs-scaled-icon-xs scale-95 cursor-pointer text-sky-600 hover:scale-100"
                >
                  <icon-ify
                    icon="mdi:checkbox-marked-outline"
                    class="h-full w-full"
                  />
                </el-icon>
                <el-icon
                  v-else
                  class="gs-scaled-icon-xs scale-95 cursor-pointer text-neutral-400 hover:scale-100"
                >
                  <icon-ify
                    icon="mdi:checkbox-blank-outline"
                    class="h-full w-full"
                  />
                </el-icon>
              </div>
            </template>
          </el-table-column>
          <template
            v-for="(_, colApiName, index) in tableCols"
            :key="`${index}`"
          >
            <el-table-column
              v-bind="tableCols[colApiName].elAttr"
              class-name="py-0.5"
            >
              <template #default="scope">
                <CellContent
                  :col-data="tableCols[colApiName]"
                  :scope-col="scope"
                />
              </template>
            </el-table-column>
            <!-- Img preview column (AdminIcon) -->
            <el-table-column
              v-if="showImgPreviewColumn && colApiName === 'ID'"
              :width="80 + (35 * userScaledRatioWidth * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100"
              class-name="gs-cell-0 gs-col-td-padding-mini  gs-td-padding-mini"
              align="center"
              label="Preview"
            >
              <template #default="{ row }">
                <el-tooltip
                  v-if="row.AdminIcon"
                  raw-content
                  :show-after="600"
                  effect="light"
                  placement="right-start"
                >
                  <template #default>
                    <el-image
                      :src="row.AdminIcon"
                      fit="contain"
                      :style="{
                        width: `${35 + (35 * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100}px`,
                        height: `${35 + (35 * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100}px`
                      }"
                    />
                  </template>
                  <template #content>
                    <el-image
                      :src="row.AdminIcon"
                      fit="contain"
                      style="width: 200px; height: 200px"
                    />
                  </template>
                </el-tooltip>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <div class="relative flex flex-1 justify-between pt-2">
          <el-progress
            v-show="loading.searchList"
            class="absolute -top-0.5 left-0 right-0"
            :percentage="100"
            color="#0e7490"
            :stroke-width="2"
            :show-text="false"
            :indeterminate="loading.searchList"
            :duration="1"
          />
          <div class="flex">
            <el-pagination
              v-model:current-page="searchPagination.currentPage"
              v-model:page-size="searchPagination.onPage"
              :page-sizes="[50, 100, 200, 500, 1000]"
              :pager-count="5"
              :size="userScaledRatio < 1.1 ? 'small' : 'default'"
              :layout="`prev ${$windowWidth >= 640 ? ', pager' : ''}, next, ${$windowWidth >= 640 ? ', sizes' : ''}`"
              :total="searchPagination.total"
            />
          </div>
          <div class="gs-font-scaled flex items-center">
            <div v-if="$store.getters['auth/userRights']?.editor && addNewByGlobalSearch">
              <div v-if="loading.searchList || !selectorModelValue || selectorModelValue?.length < 2">
                <el-tooltip
                  placement="top"
                  content="To enable type text in selector field (min 2 chars)"
                  :show-after="500"
                >
                  <div>
                    <el-button
                      type="primary"
                      plain
                      class="mr-1"
                      size="small"
                      :disabled="true"
                      :icon="icons.Plus"
                    >
                      add new
                    </el-button>
                  </div>
                </el-tooltip>
              </div>
              <div
                v-else
                class="justify-space-between flex items-center"
              >
                <el-input
                  v-model="customKey"
                  type="text"
                  size="small"
                  class="mr-2 w-28 hover:w-40"
                  placeholder="custom stringId"
                  @click.stop
                />
                <el-popconfirm
                  title="Are you sure ?"
                  :teleported="false"
                  @confirm="onConfirmAddNewByGlobalSearch"
                >
                  <template #reference>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="Add new using value of selector field"
                        :show-after="500"
                      >
                        <div>
                          <el-button
                            type="primary"
                            plain
                            class="mr-1"
                            size="small"
                            :loading="!!loading?.global_search"
                            :icon="icons.Plus"
                          >
                            add new
                          </el-button>
                        </div>
                      </el-tooltip>
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
            <el-button
              class="gs-btn-outlined-primary-light gs-height-related-sm font-related-lg"
              @click="onCancelSearchPopover"
            >
              cancel
            </el-button>
          </div>
        </div>
      </div>
    </el-popover>
    <!-- filter popover -->
    <el-popover
      :virtual-ref="$refs.buttonRef"
      :visible="filterPopupVisible"
      virtual-triggering
      placement="bottom"
      :width="widthPopover"
      :disabled="disabled"
      popper-class="p-0"
      @before-enter="loadData"
      @after-enter="focusGlobalSearch"
      @show="typePopup = 'filter'"
      @before-leave="onBeforeLeavePopover"
      @after-leave="leaveFilterPopover"
    >
      <div
        v-if="filterPopupInitiated || filterPopupVisible"
        class="gs-selector-wrapper p-2"
        :class="[$store.getters['auth/userScaledClass']]"
        @click.stop.prevent
      >
        <div
          v-if="$slots?.top"
          class="relative flex flex-1 justify-between pb-1.5"
        >
          <slot
            name="top"
            :filters="tableFilters"
            :is-full-init="fullInit"
          />
        </div>
        <el-table
          ref="tableFilter"
          :data="tableRows"
          style="width: 100%"
          :style="[{ fontSize: $store.getters['auth/userScaledFontSize'] }]"
          size="small"
          :max-height="Math.ceil(Math.max($windowHeight * 0.4, 350))"
          header-cell-class-name="border-b-4 border-neutral-50"
          highlight-current-row
          tabindex="0"
          :empty-text="loading.filterList ? 'loading data ...' : 'No Data'"
          @keydown.down="selectByOffset(1)"
          @keydown.up="selectByOffset(-1)"
          @current-change="onSelectRow"
          @row-click="confirmSelect"
          @keydown.enter="confirmSelect"
        >
          <el-table-column>
            <template #header>
              <div class="relative flex flex-1 items-center">
                <div class="gs-scaled whitespace-nowrap pr-2 text-neutral-500">
                  Global search:
                </div>
                <div class="w-5/12">
                  <CrudTypeFields
                    v-model:value-field="tableFilters.global_search"
                    :prefix-icon="icons.Search"
                    name="global_search"
                    full-width
                  />
                </div>
              </div>
            </template>
            <el-table-column width="50">
              <template #default="{ $index }">
                <div class="flex items-center justify-center">
                  <el-icon
                    v-if="currentSelectedRowIndex === $index"
                    class="gs-scaled-icon-xs scale-95 cursor-pointer text-sky-600 hover:scale-100"
                  >
                    <icon-ify
                      icon="mdi:checkbox-marked-outline"
                      class="h-full w-full"
                    />
                  </el-icon>
                  <el-icon
                    v-else
                    class="gs-scaled-icon-xs scale-95 cursor-pointer text-neutral-400 hover:scale-100"
                  >
                    <icon-ify
                      icon="mdi:checkbox-blank-outline"
                      class="h-full w-full"
                    />
                  </el-icon>
                </div>
              </template>
            </el-table-column>
            <template
              v-for="(_, colApiName, index) in tableCols"
              :key="`${index}`"
            >
              <el-table-column
                v-bind="tableCols[colApiName].elAttr"
                class-name="py-0.5"
              >
                <template #header="scope">
                  <div class="whitespace-nowrap">
                    <!-- slot header_[colApiName] -->
                    <slot
                      :name="`header_${scope.column.property}`"
                      :settings="tableCols[colApiName]"
                    >
                      {{ scope.column.label }}
                    </slot>
                    <!-- sorting -->
                    <el-button
                      v-if="tableCols[colApiName]._sortable"
                      class="gs-btn-text-neutral-light gs-sort gs-font-scaled mr-1 px-0 hover:visible"
                      :class="{
                        visible: sorting.prop === scope.column.property,
                        invisible: sorting.prop !== scope.column.property
                      }"
                      :icon="
                        sorting.prop === scope.column.property
                          ? sorting.order === 'anscending'
                            ? icons.Top
                            : icons.Bottom
                          : icons.DCaret
                      "
                      size="small"
                      @click.stop="onSort(scope.column.property)"
                    />
                  </div>
                  <!-- header_search_[colApiName] -->
                  <slot
                    :name="`header_search_${scope.column.property}`"
                    :settings="tableCols[colApiName]"
                  >
                    <div
                      v-if="
                        apiColumnsLength && tableCols[colApiName]?.filterField && tableCols[colApiName].typeApiColumn !== 'entity'
                      "
                    >
                      <CrudTypeFields
                        v-model:value-field="tableFilters[tableCols[colApiName].filterField]"
                        :type-value-field="tableCols[colApiName].typeValueField"
                        :api-options="tableCols[colApiName].typeApiColumn"
                        :prefix-icon="icons.Search"
                        :name="scope.column.property"
                        :col-api-name="colApiName"
                      />
                    </div>
                  </slot>
                </template>
                <template #default="scope">
                  <CellContent
                    :col-data="tableCols[colApiName]"
                    :scope-col="scope"
                  />
                </template>
              </el-table-column>
              <!-- Img preview column (AdminIcon) -->
              <el-table-column
                v-if="showImgPreviewColumn && colApiName === 'ID'"
                :width="80 + (35 * userScaledRatioWidth * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100"
                class-name="gs-cell-0 gs-col-td-padding-mini  gs-td-padding-mini"
                align="center"
              >
                <template #header>
                  <div style="min-height: 50px">
                    Preview
                    <el-button
                      size="small"
                      class="invisible"
                    />
                  </div>
                </template>
                <template #default="{ row }">
                  <el-tooltip
                    v-if="row.AdminIcon"
                    raw-content
                    :show-after="600"
                    effect="light"
                    placement="right-start"
                  >
                    <template #default>
                      <el-image
                        :src="row.AdminIcon"
                        fit="contain"
                        :style="{
                          width: `${35 + (35 * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100}px`,
                          height: `${35 + (35 * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1)) / 100}px`
                        }"
                      />
                    </template>
                    <template #content>
                      <el-image
                        :src="row.AdminIcon"
                        fit="contain"
                        style="width: 200px; height: 200px"
                      />
                    </template>
                  </el-tooltip>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
        </el-table>
        <div class="relative flex flex-1 justify-between pt-2">
          <el-progress
            v-show="loading.filterList"
            class="absolute -top-0.5 left-0 right-0"
            :percentage="100"
            color="#0e7490"
            :stroke-width="2"
            :show-text="false"
            :indeterminate="loading.filterList"
            :duration="1"
          />
          <div class="flex">
            <!-- refresh data -->
            <el-button
              type=""
              class="gs-btn-text-neutral-light h-auto p-0"
              :loading="loading.filterList"
              @click="loadData(null)"
            >
              <el-tooltip
                content="Refresh data"
                effect="light"
                placement="top"
                :show-after="600"
              >
                <div>
                  <icon-ify
                    v-show="!loading.filterList"
                    icon="ic:baseline-refresh"
                    class="gs-scaled-icon-xs"
                  />
                </div>
              </el-tooltip>
            </el-button>
            <el-pagination
              v-model:current-page="filterPagination.currentPage"
              v-model:page-size="filterPagination.onPage"
              :page-sizes="[50, 100, 200, 500, 1000]"
              :pager-count="5"
              :size="userScaledRatio < 1.1 ? 'small' : 'default'"
              :layout="`prev ${$windowWidth >= 640 ? ', pager' : ''}, next, ${$windowWidth >= 640 ? ', sizes' : ''}`"
              :total="filterPagination.total"
            />
            <!-- reset -->
            <el-button
              v-show="areFilters || isSorting"
              type=""
              class="gs-btn-text-neutral-light h-auto py-0"
              @click="reset(true)"
            >
              <el-tooltip
                content="reset"
                effect="light"
                placement="top"
                :show-after="600"
              >
                <div>
                  <el-icon class="gs-scaled-icon-xss">
                    <CircleClose />
                  </el-icon>
                </div>
              </el-tooltip>
            </el-button>
          </div>
          <div class="gs-font-scaled flex items-center">
            <div v-if="$store.getters['auth/userRights']?.editor && addNewByGlobalSearch">
              <div v-if="loading.filterList || !tableFilters?.global_search || tableFilters?.global_search?.length < 2">
                <el-tooltip
                  placement="top"
                  content="To enable type text in &quot;Global search&quot; field (min 2 chars)"
                  :show-after="500"
                >
                  <div>
                    <el-button
                      type="primary"
                      plain
                      class="mr-1"
                      size="small"
                      :disabled="true"
                      :icon="icons.Plus"
                    >
                      add new
                    </el-button>
                  </div>
                </el-tooltip>
              </div>
              <div
                v-else
                class="justify-space-between flex items-center"
              >
                <el-input
                  v-model="customKey"
                  type="text"
                  size="small"
                  class="mr-2 w-28 hover:w-40"
                  placeholder="custom stringId"
                  @click.stop
                />
                <el-popconfirm
                  title="Are you sure ?"
                  :teleported="false"
                  @confirm="onConfirmAddNewByGlobalSearch"
                >
                  <template #reference>
                    <div>
                      <el-tooltip
                        placement="top"
                        content="Add new using value of &quot;Global search&quot; field"
                        :show-after="500"
                      >
                        <div>
                          <el-button
                            type="primary"
                            plain
                            class="mr-1"
                            size="small"
                            :loading="!!loading?.global_search"
                            :icon="icons.Plus"
                          >
                            add new
                          </el-button>
                        </div>
                      </el-tooltip>
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
            <slot name="additionalActions" />

            <el-button
              class="gs-btn-outlined-primary-light gs-height-related-sm font-related-lg"
              @click="filterPopupVisible = false"
            >
              cancel
            </el-button>
          </div>
        </div>
      </div>
    </el-popover>
    <!-- edit dialog -->
    <el-dialog
      v-if="editDialogVisible && editModelRouteName?.length && !disableEditModel"
      v-model="editDialogVisible"
      :width="$windowWidth > 640 ? '85%' : '95%'"
      append-to-body
      align-center
      class="gs-ds-dialog relative min-h-[70vh]"
    >
      <template #header>
        <div class="flex items-center">
          <div
            class="truncate text-sm font-medium text-neutral-600"
            style="max-width: 75%"
          >
            {{ editDialogMetaData?.subtitle }}
          </div>
          <el-tooltip
            v-if="editDialogMetaData.imgUrl && !editDialogMetaData.hideImg"
            raw-content
            :show-after="600"
            effect="light"
            placement="right-end"
          >
            <template #default>
              <el-image
                :src="editDialogMetaData.imgUrl"
                fit="fill"
                class="ml-4 cursor-pointer rounded-lg"
                style="max-width: 35px; max-height: 35px"
              />
            </template>
            <template #content>
              <el-image
                :src="editDialogMetaData.imgUrl"
                fit="contain"
                style="width: 200px; height: 200px"
              />
            </template>
          </el-tooltip>
        </div>
      </template>
      <el-scrollbar
        :max-height="$windowHeight > 640 ? '70vh' : '95vh'"
        :height="$windowHeight > 640 ? '70vh' : '80vh'"
      >
        <slot
          v-if="editDialogVisible"
          name="editModelContent"
          :entity-route="editDialogRoute"
          :set-edit-model-meta-data="setMetaData"
          :close-edit-modal="closeEditDialog"
          :refresh-selector="refreshSelector"
          :is-full-init="fullInit"
        />
      </el-scrollbar>
    </el-dialog>
    <!-- relations dialog -->
    <el-dialog
      v-if="relationsOptions?.visibleDialog"
      v-model="relationsOptions.visibleDialog"
      width="85%"
      top="10vh"
      append-to-body
      class="gs-ds-dialog relative"
    >
      <template #header>
        <div class="flex items-center">
          <div
            class="truncate text-sm font-medium text-neutral-600"
            style="max-width: 80%"
          >
            {{ relationsOptions.route?.meta?.Title }} - choose relation
          </div>
        </div>
      </template>
      <slot
        v-if="relationsOptions?.visibleDialog"
        name="relationsDialogContent"
        :route="relationsOptions.route"
        :close-dialog="relationsOptions.closeDialog"
        :refresh-selector="refreshSelector"
        :selected-relation="relationValue"
        :change-relation="relationsOptions.changeRelation"
        :enabled="enabledRelations"
      />
    </el-dialog>
    <slot name="drawers" />
  </template>
</template>

<style lang="postcss">
.gs-dynamic-selector {
  .clear.invisible {
    top: -5px;
    left: -10px;
  }
  &:hover {
    .clear.invisible {
      visibility: visible !important;
    }
  }
}
.gs-selector-wrapper {
  .el-table {
    &.search-table {
      th {
        vertical-align: middle;
      }
    }

    th:hover {
      .gs-sort {
        visibility: visible !important;
      }
    }

    &:focus-visible {
      outline: none !important;
    }

    td.gs-cell-0 .cell {
      line-height: 0;
    }
  }

  &.gs-scaled- {
    &xl,
    &lg {
      th .cell {
        font-weight: 500;
      }
      td .cell {
        font-weight: 300;
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
}
.gs-ds-dialog {
  header {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    margin-right: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
