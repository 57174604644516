<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { ArrowDown, DocumentCopy, Download } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'
import { globalProperties as app } from '!/plugins/utilities'
import { userRightRoles } from '!/composition/utilities'

const crudTable = inject('crudTable')
const actionsCollapsed = ref('')
const moreActionsCollapsed = ref('')
function onHideDropdown(open) {
  if (!open) {
    actionsCollapsed.value = ''
    moreActionsCollapsed.value = ''
  }
}
const isNavigatorClipboard = ref(false)
onMounted(() => {
  isNavigatorClipboard.value = !!window?.navigator?.clipboard
  if (!crudTable.selectMode && crudTable.crudSettings.crudSelectIdsMode) {
    let storageCrudSelectIds = null
    try {
      storageCrudSelectIds = JSON.parse(localStorage.getItem('crudSelectIds'))
    } catch (_e) {
      storageCrudSelectIds = null
    }
    if (storageCrudSelectIds && storageCrudSelectIds?.routeName && storageCrudSelectIds?.routeName === crudTable.$route.name) {
      crudTable.crudSelectIds = { ...(storageCrudSelectIds?.crudSelectIds || {}) }
    } else {
      localStorage.removeItem('crudSelectIds')
    }
  } else {
    localStorage.removeItem('crudSelectIds')
  }
})

function filterNewDuplicates() {
  crudTable.toggleOnlySelectedIds(false)
  crudTable.searchFieldsRefer.typeEnv = 'duplicates'
}

const dropdownClass = computed(() => {
  if (crudTable.$route?.query?.c_sea?.typeEnv === 'duplicates') {
    return 'border-fuchsia-400 text-fuchsia-500 hover:bg-fuchsia-500/[.1] active:bg-fuchsia-500/[.15]'
  }

  if (crudTable.crudSelectIdsLength === 0) {
    return 'gs-btn-outlined-neutral'
  }

  if (crudTable.crudSelectIdsMode) {
    return 'gs-btn-outlined-warning'
  }

  return 'gs-btn-outlined-primary'
})

function clearDropDownFilters() {
  if (crudTable.crudSelectIdsMode) {
    crudTable.toggleOnlySelectedIds(false)
  } else if (crudTable.$route?.query?.c_sea?.typeEnv) {
    delete crudTable.searchFieldsRefer.typeEnv
  }
}

function applyAllItemTags() {
  ElMessageBox.prompt('Please input group ID', {
    confirmButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    inputPattern: /^\d+$/,
    inputErrorMessage: 'Required int number',
    distinguishCancelAndClose: true,
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true
        instance.confirmButtonText = 'Loading...'
        app.$axios
          .post('/admin/api/item-tag-group/set-group/', {
            group: instance.inputValue * 1,
            selected: { ...crudTable.crudSelectIds },
            entity: crudTable.crudEntity
          })
          .then(() => {
            done()
            app.$notify({
              type: 'info',
              message: `Item tag group assigned.`,
              position: 'top-right',
              customClass: 'bg-amber-50 text-amber-600 child-inherit-colors',
              offset: 30,
              duration: 9000,
              showClose: true
            })
            crudTable?.fetchData?.()
          })
          .catch(app.$utils.catchError)
          .then(() => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = 'Continue'
          })
      } else {
        done()
      }
    }
  })
    .then(() => {})
    .catch(() => {})
}
</script>

<template>
  <el-table-column
    v-if="!crudTable.selectMode && crudTable.crudSettings.crudSelectIdsMode"
    :fixed="crudTable.groupBy ? undefined : 'left'"
    :width="crudTable.versioned ? 50 : 65"
    class-name="gs-font-scaled gs-col-padding-mini"
    label-class-name="align-middle"
  >
    <template #header>
      <div
        class="absolute bottom-0 w-full pb-3 pt-2"
        :class="{ 'px-2': !crudTable.versioned }"
      >
        <div class="relative">
          <el-dropdown
            class="w-full pr-1"
            @visible-change="onHideDropdown"
          >
            <el-button
              class="gs-font-scaled relative w-full px-0.5 outline-0"
              :class="dropdownClass"
              size="small"
            >
              {{ crudTable.crudSelectIdsLength }} <el-icon class="el-icon--right">
                <ArrowDown />
              </el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="crudTable.checkAllIdFromCurrentPage">
                  <icon-ify
                    icon="mdi:checkbox-marked-outline"
                    class="h-full"
                  />
                  <span class="pl-2">check all on this page</span>
                </el-dropdown-item>
                <el-dropdown-item
                  :disabled="!crudTable.crudSelectIdsLength"
                  @click="crudTable.uncheckAllIdFromCurrentPage"
                >
                  <icon-ify
                    icon="mdi:checkbox-blank-outline"
                    class="h-full"
                  />
                  <span class="pl-2">uncheck all on this page</span>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="isNavigatorClipboard"
                  @click="crudTable.pasteIdsFromClipboard"
                >
                  <icon-ify
                    icon="carbon:paste"
                    class="h-full"
                  />
                  <span class="pl-2">paste ids from clipboard</span>
                </el-dropdown-item>
                <el-dropdown-item
                  divided
                  :disabled="!crudTable.crudSelectIdsLength"
                  class="relative"
                  @click="crudTable.toggleOnlySelectedIds(true)"
                >
                  <icon-ify
                    icon="mdi:filter-check-outline"
                    class="h-full"
                  />
                  <span class="pl-2">show only selected (all)</span>
                  <el-button
                    v-if="crudTable.crudSelectIdsMode"
                    class="absolute right-2 top-0 p-0.5 outline-0 gs-btn-outlined-neutral-light"
                    size="small"
                    circle
                    @click.stop="clearDropDownFilters"
                  >
                    <icon-ify
                      icon="mdi:filter-remove"
                      class="text-amber-600"
                    />
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item
                  divided
                  :disabled="!crudTable.crudSelectIdsLength"
                  @click="crudTable.uncheckAllSelectIds"
                >
                  <icon-ify
                    icon="ic:twotone-clear"
                    class="h-full"
                  />
                  <span class="pl-2">uncheck all (on all pages)</span>
                </el-dropdown-item>
                <el-collapse
                  v-if="$utils.checkRights(userRightRoles.editor)"
                  v-model="actionsCollapsed"
                  class="px-4"
                >
                  <el-collapse-item
                    title="Actions"
                    name="actions"
                    style="--el-collapse-header-height: 36px; --el-collapse-header-text-color: #606266"
                    :disabled="!crudTable.crudSelectIdsLength"
                  >
                    <template #title>
                      <icon-ify
                        icon="mdi:database-edit-outline"
                        class="h-full"
                      />
                      <span class="pl-2">Actions on selected</span>
                    </template>
                    <template v-if="crudTable.usedTopActions?.excelExport && !crudTable.selectMode">
                      <el-dropdown-item
                        v-if="crudTable.versioned"
                        :disabled="!crudTable.crudSelectIdsLength"
                        @click.stop
                      >
                        <div
                          class="gs-font-scaled grow cursor-text"
                          @click.stop
                        >
                          <icon-ify
                            icon="file-icons:microsoft-excel"
                            class="inline-block"
                          />
                          <span class="pl-2">export</span>
                        </div>
                        <div class="mr-1">
                          <el-button
                            plain
                            type="primary"
                            :size="$store.getters['auth/userScaledMediumSize']"
                            class="px-1"
                            :disabled="!crudTable.crudSelectIdsLength"
                            @click="crudTable.excelExportVersioned('selected')"
                          >
                            <el-icon class="gs-scaled-icon-xss m-0">
                              <DocumentCopy />
                            </el-icon>
                          </el-button>
                        </div>
                        <div class="ml-2">
                          <el-button
                            plain
                            type="primary"
                            :size="$store.getters['auth/userScaledMediumSize']"
                            class="px-1"
                            :disabled="!crudTable.crudSelectIdsLength"
                            @click="crudTable.excelExportVersionedToFile('selected')"
                          >
                            <el-icon class="gs-scaled-icon-xss m-0">
                              <Download />
                            </el-icon>
                          </el-button>
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-else
                        :disabled="!crudTable.crudSelectIdsLength"
                        @click="crudTable.excelExport(true)"
                      >
                        <div class="gs-font-scaled grow">
                          <icon-ify
                            icon="file-icons:microsoft-excel"
                            class="inline-block"
                          />
                          <span class="pl-2">export</span>
                        </div>
                        <div class="mr-1">
                          <el-button
                            plain
                            type="primary"
                            :size="$store.getters['auth/userScaledMediumSize']"
                            class="px-1"
                            :disabled="!crudTable.crudSelectIdsLength"
                          >
                            <el-icon class="gs-scaled-icon-xss m-0">
                              <DocumentCopy />
                            </el-icon>
                          </el-button>
                        </div>
                      </el-dropdown-item>
                    </template>
                    <template v-if="!crudTable.selectMode">
                      <el-dropdown-item
                        v-if="$utils.checkRights(crudTable.actionsOnListSettings?.crudDelete?.right || userRightRoles.editor)"
                        :disabled="!crudTable.crudSelectIdsLength"
                        @click="crudTable.triggerBuiltInActions('crudDelete', { ids: Object.keys(crudTable.crudSelectIds) })"
                      >
                        <icon-ify
                          icon="mdi:delete-forever-outline"
                          class="h-full"
                        />
                        <span class="pl-2">delete (only dev)</span>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="
                          $utils.checkRights(userRightRoles.editor) && crudTable.versioned && !crudTable.disableMultiDuplicates
                        "
                        :disabled="!crudTable.crudSelectIdsLength"
                        @click="crudTable.triggerBuiltInActions('crudDuplicate', { ids: Object.keys(crudTable.crudSelectIds) })"
                      >
                        <icon-ify
                          icon="heroicons:document-duplicate"
                          class="h-full"
                        />
                        <span class="pl-2">duplicate</span>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="
                          $utils.checkRights(userRightRoles.editor) && crudTable.versioned && !crudTable.disableMultiDuplicates
                        "
                        :disabled="!crudTable.crudSelectIdsLength"
                        @click="crudTable.triggerBuiltInActions('crudDuplicateWithEdit', { ids: Object.keys(crudTable.crudSelectIds) })"
                      >
                        <icon-ify
                          icon="heroicons:document-duplicate"
                          class="h-full"
                        />
                        <span class="pl-2">duplicate with edit</span>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="
                          $utils.checkRights(userRightRoles.editor) && crudTable.versioned && !crudTable.disableMultiDuplicates
                        "
                        :disabled="!crudTable.crudSelectIdsLength"
                        @click="crudTable.triggerBuiltInActions('crudMultiEdit', { ids: Object.keys(crudTable.crudSelectIds) })"
                      >
                        <icon-ify
                          icon="mdi:file-document-edit-outline"
                          class="h-full"
                        />
                        <span class="pl-2">multi edit</span>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="
                          crudTable.enabledItemTagsRelations
                            && $utils.checkRights(crudTable.actionsOnListSettings?.crudDelete?.right || userRightRoles.editor)
                        "
                        class="relative"
                        :disabled="!crudTable.crudSelectIdsLength"
                        @click.stop="applyAllItemTags"
                      >
                        <icon-ify
                          icon="ph:link-bold"
                          class="h-full"
                        />
                        <span class="pl-2">Apply item tag group</span>
                      </el-dropdown-item>
                    </template>
                  </el-collapse-item>
                </el-collapse>
                <el-collapse
                  v-if="crudTable.versioned && !crudTable.disableMultiDuplicates"
                  v-model="moreActionsCollapsed"
                  class="px-4"
                >
                  <el-collapse-item
                    title="More actions"
                    name="MoreActions"
                    style="--el-collapse-header-height: 36px; --el-collapse-header-text-color: #606266"
                  >
                    <template #title>
                      <icon-ify
                        icon="gg:more-r"
                        class="h-full"
                      />
                      <span class="pl-2">More actions</span>
                    </template>
                    <el-dropdown-item
                      v-if="crudTable.versioned && !crudTable.disableMultiDuplicates"
                      class="relative"
                      @click.stop="filterNewDuplicates"
                    >
                      <icon-ify
                        icon="mdi:filter-outline"
                        class="h-full"
                      />
                      <span class="pl-2">Filter new duplicates</span>
                      <el-button
                        v-if="crudTable.$route?.query?.c_sea?.typeEnv === 'duplicates'"
                        class="absolute right-0 top-0 p-0.5 outline-0 gs-btn-outlined-neutral-light"
                        size="small"
                        circle
                        @click.stop="clearDropDownFilters"
                      >
                        <icon-ify
                          icon="mdi:filter-remove"
                          class="text-amber-600"
                        />
                      </el-button>
                    </el-dropdown-item>
                  </el-collapse-item>
                </el-collapse>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-tooltip
            v-if="crudTable.crudSelectIdsMode || crudTable.$route?.query?.c_sea?.typeEnv === 'duplicates'"
            content="clear multi select filters"
            :show-after="500"
            placement="top"
            effect="light"
          >
            <el-button
              class="absolute -right-2 -top-2.5 p-0.5 outline-0 gs-btn-outlined-neutral-light"
              size="small"
              circle
              @click="clearDropDownFilters"
            >
              <icon-ify
                icon="mdi:filter-remove"
                class="text-amber-600"
              />
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </template>
    <template #default="{ row }">
      <div
        v-if="!crudTable.versioned || (crudTable.versioned && row.children.length < 2)"
        class="flex items-center justify-center"
        @click="crudTable.selectIdFromRow(row)"
      >
        <el-icon
          v-if="crudTable.crudSelectIds?.[row?.ID]"
          class="gs-scaled-icon-xs scale-95 cursor-pointer text-sky-600 hover:scale-100"
        >
          <icon-ify
            icon="mdi:checkbox-marked-outline"
            class="h-full w-full"
          />
        </el-icon>
        <el-icon
          v-else
          class="gs-scaled-icon-xs scale-95 cursor-pointer text-neutral-400 hover:scale-100"
        >
          <icon-ify
            icon="mdi:checkbox-blank-outline"
            class="h-full w-full"
          />
        </el-icon>
      </div>
    </template>
  </el-table-column>
</template>
