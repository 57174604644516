import { convertArrayToCSV } from 'convert-array-to-csv'
import { userRightRoles } from '!/composition/utilities'

const Actions = {
  data() {
    return {
      loadingActions: {},
      visibleImportDialog: false
    }
  },
  computed: {
    tableActionsList() {
      const actions = Object.fromEntries(
        Object.entries(this.actionsOnListSettings).filter(([actionName, actionSettings]) => {
          return (
            this.actionsOnListSettings?.[actionName] !== false &&
            this.$utils.checkRights(actionSettings?.right || userRightRoles.editor)
          )
        })
      )
      if (this.versioned) {
        delete actions.crudDelete
      }
      return actions
    },
    tableActionsListArr() {
      return (
        Object.entries(this.tableActionsList).map(([actionName, actionConfig]) => {
          return { ...actionConfig, prop: actionName }
        }) || []
      )
    },
    usedTopActions() {
      let topActions = null
      if (this.topActions) {
        topActions = Object.fromEntries(
          Object.entries(this.topActions).map(([topActionName, isEnabled]) => {
            return [
              topActionName,
              isEnabled && this.$utils.checkRights(this.$props[`${topActionName}Settings`]?.right || userRightRoles.editor)
            ]
          })
        )
      }
      return topActions
    },
    bindActionsColAttr() {
      const attr = {
        ...this.actionColSettings,
        ...this.actionColSettings?.elAttr,
        fixed: this.$store.getters['auth/userLocalSettings']?.actionColum || 'right'
      }
      if (this.$windowWidth < 640) {
        attr.width = Math.ceil((attr.width / 3) * 2)
      }
      attr.width = attr.width + this.$store.getters['auth/userScaledWidth']
      attr['class-name'] = `${attr['class-name'] || ''} gs-font-scaled gs-col-padding-mini`
      delete attr.elAttr
      return attr
    }
  },
  methods: {
    excelExport(selected = false) {
      if (!this.total || this.total > this.excelExportSettings.maxRows || !this.usedTopActions?.excelExport)
        return false
      this.loadingActions.excelExport = true
      const requests = []
      for (let offset = 0; offset * this.excelExportSettings.chunks < this.total; offset++) {
        let params = {}
        if (selected) {
          params = {
            page: offset + 1,
            ...this.proxyRequestParams,
            on_page: this.excelExportSettings.chunks,
            columns: this.currentTableVisibleColumnsNames.join(','),
            ...this.staticApiParams,
            selected_ids: Object.keys(this.crudSelectIds)
          }
        } else {
          params = {
            page: offset + 1,
            ...this.proxyRequestParams,
            on_page: this.excelExportSettings.chunks,
            ...this.getSortingParams(),
            ...this.filterParams.all,
            ...this.searchParams.all,
            columns: this.currentTableVisibleColumnsNames.join(','),
            ...this.staticApiParams,
            ...(this.crudSettings.crudSelectIdsMode && this.crudSelectIdsMode && this.crudSelectIdsLength
              ? { selected_ids: Object.keys(this.crudSelectIds) }
              : {})
          }
        }
        if (params?.dynamicUriParams?.length) {
          const query = new URLSearchParams()
          params.dynamicUriParams.forEach((paramKey) => {
            if (params?.[paramKey] !== undefined) {
              query.append(paramKey, params[paramKey])
              delete params[paramKey]
            }
          })
          params.uri += `?${query.toString()}`
        }

        requests.push(this.$axios.get(`${this.apiPrefix + this.api}/`, { params }))
      }
      Promise.all(requests)
        .then((data) => {
          if (!data.length) {
            this.$message({
              message: 'no rows',
              type: 'warning',
              offset: 40
            })
          }
          const exportedRows = []
          data.forEach((resp) => {
            (resp?.data?.items || []).forEach((row) => {
              const exportRow = [row?.[this.indexColumn]]
              this.tableVisibleColumns.forEach((col) => {
                if (col.elAttr.prop === this.indexColumn)
                  return false

                let colValue = row?.[col.elAttr.prop]
                if (col.typeValueField === 'clientstring' && colValue) {
                  const parts = colValue.AdminLabel.split(':')
                  colValue = `${parts[0]}|${parts.slice(1).join(':').trimStart()}`
                }
                exportRow.push(colValue)
              })
              exportRow.forEach((value, index) => {
                if (value?.ID) {
                  exportRow[index] = value.ID
                }
              })
              exportedRows.push(exportRow)
            })
          })
          const exportedHeaders = [`${this.indexColumn}.${this.crudEntity}`]
          this.tableVisibleColumns.forEach((col) => {
            if (col.elAttr.prop === this.indexColumn)
              return false
            const parts = col.elAttr.prop.split('.')
            if (parts.length > 0 && parts[1] === 'AdminLabel') {
              exportedHeaders.push(`${col.elAttr.prop}[PREVIEW]`)
            } else {
              exportedHeaders.push(col.elAttr.prop)
            }
          })
          this.$utils
            .copyToClipboard(
              convertArrayToCSV(exportedRows, {
                header: exportedHeaders,
                separator: '\t'
              })
            )
            .then(() => {
              this.$message({
                message: 'copied to clipboard',
                type: 'success',
                offset: 40
              })
              this.loadingActions.excelExport = false
            })
            .catch(() => {
              this.$message({
                message: 'copy to clipboard failed',
                type: 'error',
                offset: 40
              })
              this.loadingActions.excelExport = false
            })
        })
        .catch((error) => {
          this.loadingActions.excelExport = false
          this.$utils.catchError(error)
        })
    },
    triggerBuiltInActions(actionName, row) {
      if (actionName.slice(0, 4) === 'crud' && this.$options.methods?.[`${actionName}Action`]) {
        const action = this[`${actionName}Action`](row)
        if (typeof action === 'object') {
          return this.$utils.bindStaticParams(action, !!this.$route?.query?.debug && actionName === 'crudPreview')
        }

        return action
      }
    },
    crudPreviewAction(row = {}) {
      try {
        let routeObj = ''
        if (typeof this.addNewSettings?.routeName === 'function') {
          routeObj = this.addNewSettings.routeName(row)
        } else if (this.addNewSettings?.routeName) {
          routeObj = { name: this.addNewSettings.routeName, params: { id: row?.ID || 0 } }
        } else {
          routeObj = { name: `${this.$route.name}-details`, params: { id: row?.ID || 0 } }
        }
        const detailRoute = this.$utils.getFullRouteData(routeObj, true)
        if (detailRoute) {
          const query = {}
          if (this.addNewSettings?.routeName) {
            query.back = this.$route.name
          }
          if (this?.proxyRequestParams?.env && this.crudSearchFields?.env) {
            query.env = this.crudSearchFields.env
          }
          return { name: detailRoute.name, params: { id: row?.ID || 0 }, query }
        }
      } catch (_e) {
        return ''
      }
      return ''
    },
    crudDeleteAction(row = {}) {
      this.$messageBox
        .confirm('Are you sure to delete this ?', undefined, {
          confirmButtonText: 'Yes, delete',
          type: 'warning'
        })
        .then(() => {
          const params = {}
          if (row?.ids?.length) {
            this.loadingActions.crudAction_multi = true
            params.ids = row.ids
          } else {
            this.loadingActions[`crudDelete_${row?.ID || 0}`] = true
            params.id = row?.ID || 0
          }
          this.$axios
            .delete(`${this.apiPrefix + (this.actionsOnListSettings?.crudDelete?.api || this.apiItem)}/`, {
              params
            })
            .then(() => {
              if (row?.ids?.length) {
                row.ids.forEach((id) => {
                  delete this.crudSelectIds[id]
                })
              }
              this.fetchData()
              this.$message({
                message: 'deleted',
                type: 'success',
                offset: 40
              })
            })
            .catch(this.$utils.catchError)
            .then(() => {
              if (row?.ids?.length) {
                this.loadingActions.crudAction_multi = false
              } else {
                this.loadingActions[`crudDelete_${row?.ID || 0}`] = false
              }
            })
        })
    },
    crudDuplicateAction(row = {}) {
      if (row?.ids?.length) {
        this.$messageBox
          .confirm(`Are you sure you want to duplicate ${row.ids.length} row${row.ids.length > 1 ? 's' : ''} ?`, undefined, {
            confirmButtonText: 'Yes, duplicate',
            type: 'warning'
          })
          .then(() => {
            this.loadingActions.crudAction_multi = true
            const params = { ids: row.ids.map(id => id * 1), entity: this.crudEntity }
            this.$axios
              .post(`${this.apiPrefix}entities/duplicate/`, params)
              .then(() => {
                if (row?.ids?.length) {
                  row.ids.forEach((id) => {
                    delete this.crudSelectIds[id]
                  })
                }
                this.fetchData()
                this.$message({
                  message: 'duplicated',
                  type: 'success',
                  offset: 40
                })
              })
              .catch(this.$utils.catchError)
              .then(() => {
                this.loadingActions.crudAction_multi = false
              })
          })
      }
    },
    crudDuplicateWithEditAction(row = {}) {
      if (row?.ids?.length) {
        const routeObj = this.crudPreviewAction({ ID: row.ids[0] })
        if (routeObj.name) {
          routeObj.query = {
            cdwe: row.ids.join(',')
          }
          this.$router.push(routeObj)
        }
      }
    },
    crudMultiEditAction(row = {}) {
      if (row?.ids?.length) {
        const routeObj = this.crudPreviewAction({ ID: row.ids[0] })
        if (routeObj.name) {
          routeObj.query = {
            cme: row.ids.join(',')
          }
          this.$router.push(routeObj)
        }
      }
    }
  }
}

export { Actions }
